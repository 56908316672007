import { CircularProgress, Select } from '@mui/material'

import React, { useState } from 'react'
import {
    Box,
    MenuItem,
    Button,
    List,
    ListItem,
    ListItemText,
    Typography,
    Modal,
    Tooltip,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'
import Failed from '../../FormStatusIcons/Failed'
import Success from '../../FormStatusIcons/Success'

const CustomerPreferences = ({ user, handleToggle }) => {
    const theme = useTheme()
    const stripe = useStripe()
    const elements = useElements()
    const { getAccessTokenSilently } = useAuth0()
    const [modalOpen, setModalOpen] = useState(false)
    const [contactMethod, setContactMethod] = useState(
        user?.preferences?.contact_method || 'email'
    )
    const [routeNotifications, setRouteNotifications] = useState(
        user?.preferences?.route_notifications || 'none'
    )
    const [paymentNotifications, setPaymentNotifications] = useState(
        user?.preferences?.payment_notifications || 'none'
    )
    const [autopayEnabled, setAutopayEnabled] = useState(
        user?.preferences?.auto_pay_enabled || 'disabled'
    )
    const [autopayCard, setAutopayCard] = useState(
        user?.preferences?.auto_pay_card || ''
    )

    const [cardToDelete, setCardToDelete] = useState(null)

    const [paymentMethods, setPaymentMethods] = useState(
        user?.payment_methods || []
    )

    const [cardStatus, setCardStatus] = useState('idle')
    const [cardStatusMessage, setCardStatusMessage] = useState('')

    const [savingStatus, setSavingStatus] = useState('idle')
    const [savingStatusMessage, setSavingStatusMessage] = useState('')

    const [deleteCardStatus, setDeleteCardStatus] = useState('idle')
    const [deleteCardStatusMessage, setDeleteCardStatusMessage] = useState('')

    const handleInputChange = (event) => {
        const { name, value } = event.target
        switch (name) {
            case 'contactMethod':
                setContactMethod(value)
                break
            case 'routeNotifications':
                setRouteNotifications(value)
                break
            case 'paymentNotifications':
                setPaymentNotifications(value)
                break
            case 'autopayEnabled':
                setAutopayEnabled(value)
                break
            case 'autopayCard':
                setAutopayCard(value)
                break

            default:
                break
        }
    }

    const savePreferences = () => {
        //if customer selected to enable autopay and has no saved cards, show error
        if (autopayEnabled === 'enabled' && paymentMethods.length === 0) {
            alert('Please add a card to enable autopay')
            return
        }
        if (autopayEnabled === 'enabled' && !autopayCard) {
            alert('Please select a card to use for autopay')
            return
        }

        //save the preferences

        setSavingStatus('loading')
        const preferences = {
            contact_method: contactMethod,
            route_notifications: routeNotifications,
            payment_notifications: paymentNotifications,
            auto_pay_enabled: autopayEnabled,
            auto_pay_card: autopayCard,
        }

        const savePreferences = async () => {
            const accessToken = await getAccessTokenSilently()
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_DB}/updatePreferences`,
                    {
                        user_id: user.user_id,
                        preferences: preferences,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )

                if (response.status === 200) {
                    setSavingStatus('success')
                    setSavingStatusMessage('Preferences saved successfully.')
                    setTimeout(() => {
                        setSavingStatus('idle')
                        setSavingStatusMessage('')
                        handleToggle(null, 'Account')
                    }, 2000)
                } else {
                    setSavingStatus('error')
                    setSavingStatusMessage(
                        'Failed to save preferences, please try again later.'
                    )
                    setTimeout(() => {
                        setSavingStatus('idle')
                        setSavingStatusMessage('')
                    }, 5000)
                }
            } catch (err) {
                setSavingStatus('error')
                setSavingStatusMessage(
                    'Failed to save preferences, please try again later.'
                )
                setTimeout(() => {
                    setSavingStatus('idle')
                    setSavingStatusMessage('')
                }, 5000)
            }
        }
        savePreferences()
    }

    const handleAddCard = async (event) => {
        setCardStatus('loading')
        event.preventDefault()
        if (!stripe || !elements) {
            setCardStatus('error')
            setCardStatusMessage(
                'Failed to load payment form, please try again later.'
            )
            return
        }

        const cardElement = elements.getElement(CardElement)
        const { error, token } = await stripe.createToken(cardElement)

        if (error) {
            setCardStatus('error')
            setCardStatusMessage(error.message)
            setTimeout(() => {
                setCardStatus('idle')
                setCardStatusMessage('')
            }, 5000)
        } else {
            const accessToken = await getAccessTokenSilently()
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_DB}/addAutoPayCard`,
                    {
                        token: token.id,
                        user_id: user.user_id,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )

                if (response.status === 200) {
                    setPaymentMethods(response.data.data)
                    setCardStatus('success')
                    setCardStatusMessage('Card saved successfully.')
                    setTimeout(() => {
                        setCardStatus('idle')
                        setCardStatusMessage('')
                        setModalOpen(false)
                    }, 2000)
                } else {
                    setCardStatus('error')
                    setCardStatusMessage(
                        'Failed to save card, please try again later.'
                    )
                    setTimeout(() => {
                        setCardStatus('idle')
                        setCardStatusMessage('')
                    }, 5000)
                }
            } catch (err) {
                setCardStatus('error')
                setCardStatusMessage(
                    'Failed to save card, please try again later.'
                )
                setTimeout(() => {
                    setCardStatus('idle')
                    setCardStatusMessage('')
                }, 5000)
            }
        }
    }

    const deleteAutopayCard = async (card) => {
        const accessToken = await getAccessTokenSilently()
        setDeleteCardStatus('loading')
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_DB}/deleteAutoPayCard`,
                {
                    paymentMethodId: card.id,
                    user_id: user.user_id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )

            if (response.status === 200) {
                setDeleteCardStatus('success')
                setDeleteCardStatusMessage('Card deleted successfully.')

                setPaymentMethods(response.data.data)
                if (paymentMethods.length === 0) {
                    setAutopayEnabled('disabled')
                    setAutopayCard('')
                }
                setTimeout(() => {
                    setDeleteCardStatus('idle')
                    setDeleteCardStatusMessage('')
                    setCardToDelete(null)
                }, 2000)
            }
        } catch (err) {
            setDeleteCardStatus('error')
            setDeleteCardStatusMessage(
                `Failed to delete card, please try again later. Error: ${err}`
            )
            setTimeout(() => {
                setDeleteCardStatus('idle')
                setDeleteCardStatusMessage('')
            }, 5000)
        }
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width={'100%'}
            color={theme.palette.primary.textWhite}
        >
            <Box p={2}>
                <Typography variant="h4">Account Preferences</Typography>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                alignItems="left"
                width={'90%'}
                color={theme.palette.primary.textWhite}
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    width={'100%'}
                    justifyContent="space-between"
                >
                    <Box p={1}>
                        <Typography variant="h6">Contact Method</Typography>
                        <Typography variant="body2">
                            If we ever need to reach out personally, we'll try
                            this method first.
                        </Typography>
                    </Box>

                    <Select
                        name="contactMethod"
                        value={contactMethod}
                        onChange={handleInputChange}
                        style={{
                            backgroundColor: theme.palette.primary.textWhite,
                        }}
                    >
                        <MenuItem value="email">Email</MenuItem>
                        <MenuItem value="phone">Phone Call</MenuItem>
                        <MenuItem value="text">Text Message</MenuItem>
                    </Select>
                </Box>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    width={'100%'}
                    justifyContent="space-between"
                >
                    <Box p={1}>
                        <Typography variant="h6">
                            Route Notifications
                        </Typography>
                        <Typography variant="body2">
                            Get automated alerts when your property is added to
                            an upcoming route.
                        </Typography>
                    </Box>
                    <Select
                        name="routeNotifications"
                        value={routeNotifications}
                        onChange={handleInputChange}
                        style={{
                            backgroundColor: theme.palette.primary.textWhite,
                        }}
                    >
                        <MenuItem value="email">Email</MenuItem>
                        <MenuItem value="none">None</MenuItem>
                    </Select>
                </Box>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    width={'100%'}
                    justifyContent="space-between"
                >
                    <Box p={1}>
                        <Typography variant="h6">Saved Cards</Typography>
                        {paymentMethods?.length === 0 ? (
                            <Typography variant="body2">
                                You have no saved cards.
                            </Typography>
                        ) : (
                            <List>
                                {paymentMethods.map((card) => (
                                    <ListItem key={card?.id}>
                                        <ListItemText
                                            primary={
                                                card?.card?.last4
                                                    ? `**** **** **** ${card?.card?.last4}`
                                                    : ''
                                            }
                                        />
                                        <Box pl={1}>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => {
                                                    setCardToDelete(card)
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </Box>
                                    </ListItem>
                                ))}
                                <Modal
                                    open={cardToDelete != null}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box
                                        p={2}
                                        bgcolor="white"
                                        borderRadius={4}
                                        width={400}
                                    >
                                        <Typography variant="h6" gutterBottom>
                                            Delete Card
                                        </Typography>
                                        <Typography variant="body2">
                                            Are you sure you want to delete the
                                            card ending in{' '}
                                            {cardToDelete?.card?.last4}?
                                        </Typography>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Box p={1}>
                                                {deleteCardStatus ===
                                                    'error' && (
                                                    <Failed
                                                        error={
                                                            deleteCardStatusMessage
                                                        }
                                                    />
                                                )}
                                                {deleteCardStatus ===
                                                    'success' && (
                                                    <Success
                                                        title={
                                                            deleteCardStatusMessage
                                                        }
                                                    />
                                                )}
                                                {deleteCardStatus ===
                                                'loading' ? (
                                                    <Button
                                                        disabled
                                                        variant="contained"
                                                    >
                                                        <CircularProgress
                                                            sx={{
                                                                color: theme
                                                                    .palette
                                                                    .primary
                                                                    .textBlack,
                                                            }}
                                                        />
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => {
                                                            deleteAutopayCard(
                                                                cardToDelete
                                                            )
                                                        }}
                                                    >
                                                        Yes
                                                    </Button>
                                                )}
                                            </Box>
                                            <Box p={1}>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => {
                                                        setCardToDelete(null)
                                                    }}
                                                >
                                                    No
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Modal>
                            </List>
                        )}
                    </Box>
                    <Box p={1}>
                        <Button
                            variant="outlined"
                            onClick={() => setModalOpen(true)}
                        >
                            Add Card
                        </Button>
                        <Modal
                            open={modalOpen}
                            onClose={() => setModalOpen(false)}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Box
                                p={2}
                                bgcolor="white"
                                borderRadius={4}
                                width={400}
                            >
                                <Typography variant="h6" gutterBottom>
                                    Add Card
                                </Typography>
                                <form onSubmit={handleAddCard}>
                                    <Box mb={2}>
                                        <CardElement />
                                    </Box>
                                    {cardStatus === 'error' && (
                                        <Box p={1}>
                                            <Failed error={cardStatusMessage} />
                                        </Box>
                                    )}
                                    {
                                        //if card is saved successfully
                                        cardStatus === 'success' && (
                                            <Box p={1}>
                                                <Success
                                                    title={cardStatusMessage}
                                                />
                                            </Box>
                                        )
                                    }
                                    {
                                        //if card is loading
                                        cardStatus === 'loading' ? (
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                            >
                                                <CircularProgress
                                                    sx={{
                                                        color: theme.palette
                                                            .primary.textBlack,
                                                    }}
                                                />
                                            </Button>
                                        ) : (
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                            >
                                                Save Card
                                            </Button>
                                        )
                                    }
                                </form>
                            </Box>
                        </Modal>
                    </Box>
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    width={'100%'}
                    justifyContent="space-between"
                >
                    <Box p={1}>
                        <Typography variant="h6">Enable Auto Pay</Typography>
                        <Typography variant="body2">
                            Automatically pay your bill each month using the
                            card on file.
                        </Typography>
                    </Box>
                    <Box p={1}>
                        <Select
                            name="autopayEnabled"
                            value={autopayEnabled}
                            onChange={handleInputChange}
                            style={{
                                backgroundColor:
                                    theme.palette.primary.textWhite,
                            }}
                        >
                            {paymentMethods.length === 0 ? (
                                <Tooltip title="You need to add a payment method to enable autopay.">
                                    <span>
                                        <MenuItem value="enabled" disabled>
                                            Enabled
                                        </MenuItem>
                                    </span>
                                </Tooltip>
                            ) : (
                                <MenuItem value="enabled">Enabled</MenuItem>
                            )}
                            <MenuItem value="disabled">Disabled</MenuItem>
                        </Select>
                    </Box>
                </Box>

                {
                    //if autopay is enabled then display a dropdown to select the card to use
                    autopayEnabled === 'enabled' &&
                        paymentMethods.length > 0 && (
                            <Box display="flex" flexDirection="column">
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    width={'100%'}
                                    justifyContent="space-between"
                                >
                                    <Box p={1}>
                                        <Typography variant="h6">
                                            Auto Pay Card
                                        </Typography>
                                        <Typography variant="body2">
                                            Select the card to use for autopay.
                                        </Typography>
                                    </Box>
                                    <Select
                                        name="autopayCard"
                                        value={autopayCard}
                                        onChange={handleInputChange}
                                        style={{
                                            backgroundColor:
                                                theme.palette.primary.textWhite,
                                        }}
                                    >
                                        {paymentMethods.map((card) => (
                                            <MenuItem
                                                key={card?.id}
                                                value={card?.id}
                                            >
                                                {card?.card?.last4
                                                    ? `**** **** **** ${card?.card?.last4}`
                                                    : ''}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    width={'100%'}
                                    justifyContent="space-between"
                                >
                                    <Box p={1}>
                                        <Typography variant="h6">
                                            Payment Notifications
                                        </Typography>
                                        <Typography variant="body2">
                                            Get alerted when an autopay payment
                                            is processed.
                                        </Typography>
                                    </Box>
                                    <Select
                                        name="paymentNotifications"
                                        value={paymentNotifications}
                                        onChange={handleInputChange}
                                        style={{
                                            backgroundColor:
                                                theme.palette.primary.textWhite,
                                        }}
                                    >
                                        <MenuItem value="email">Email</MenuItem>
                                        <MenuItem value="none">None</MenuItem>
                                    </Select>
                                </Box>
                            </Box>
                        )
                }

                <Box p={2}>
                    {
                        <Box p={1}>
                            {
                                //if saving preferences failed
                                savingStatus === 'error' && (
                                    <Failed error={savingStatusMessage} />
                                )
                            }
                            {
                                //if saving preferences was successful
                                savingStatus === 'success' && (
                                    <Success title={savingStatusMessage} />
                                )
                            }
                        </Box>
                    }
                    <Box
                        p={1}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Box p={1}>
                            {
                                //if saving preferences
                                savingStatus === 'loading' ? (
                                    <Button disabled variant="contained">
                                        <CircularProgress
                                            sx={{
                                                color: theme.palette.primary
                                                    .textBlack,
                                            }}
                                        />
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={savePreferences}
                                        variant="contained"
                                    >
                                        Save
                                    </Button>
                                )
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default CustomerPreferences
