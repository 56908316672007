//Invoices component

//This is the invoices component. It will be imported into the customer dashboard.

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Typography } from '@mui/material'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import { useTheme } from '@mui/material/styles'
import CreatePaymentPopover from '../../../modals/CreatePaymentPopover/CreatePaymentPopover'
import { useAuth0 } from '@auth0/auth0-react'
import ViewPaymentPopover from '../../../modals/ViewPaymentPopover/ViewPaymentPopover'
import ConfirmDeletePopover from '../../../modals/confirmDeletePopover/confirmDeletePopover'
import moment from 'moment'
import { Button } from '@mui/material'
import { Card, CardContent } from '@mui/material'
import { Grid } from '@mui/material'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'

export default function Payments(props) {
    const { getAccessTokenSilently } = useAuth0()
    const { customerID, getContractorDashboard } = props
    const [payments, setPayments] = useState([])
    const [createPaymentPopover, setCreatePaymentPopover] = useState(false)
    const [viewPaymentPopover, setViewPaymentPopover] = useState(false)
    const [deletePaymentPopover, setDeletePaymentPopover] = useState(false)
    const [viewPayment, setViewPayment] = useState(null)
    const [fetchStatus, setFetchStatus] = useState('idle')
    const [paymentToDelete, setPaymentToDelete] = useState(null)
    const [dateRange, setDateRange] = React.useState(null)
    const [formStatus, setFormStatus] = useState('')
    const theme = useTheme()

    const { RangePicker } = DatePicker

    const handleDeletePayment = (payment) => {
        setPaymentToDelete(payment)
        setDeletePaymentPopover(true)
    }

    const deletePayment = (paymentID) => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            axios
                .delete(
                    `${process.env.REACT_APP_BACKEND_DB}/deletePayment/${paymentID}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then(() => {
                    setDeletePaymentPopover(false)
                    setPayments(
                        payments.filter(
                            (payment) => payment.payment_id !== paymentID
                        )
                    )
                })
        }
        getJWT()
    }

    const clickViewPayment = (payment) => {
        setViewPayment(payment)
        setViewPaymentPopover(!viewPaymentPopover)
    }

    const filteredPayments = payments.filter((payment) => {
        if (!dateRange?.length) {
            return true
        }

        const paymentDate = dayjs(payment.payment_date)
        return (
            paymentDate.isAfter(dateRange[0]) &&
            paymentDate.isBefore(dateRange[1])
        )
    })

    useEffect(() => {
        if (deletePaymentPopover) {
            return
        }

        const getJWT = async () => {
            setFetchStatus('loading')
            const accessToken = await getAccessTokenSilently()

            axios
                .get(
                    `${process.env.REACT_APP_BACKEND_DB}/getPayments/${customerID}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    setFetchStatus('success')
                    setPayments(response.data)
                })
        }
        if (!createPaymentPopover) {
            getJWT()
        }
    }, [
        customerID,
        createPaymentPopover,
        getAccessTokenSilently,
        deletePaymentPopover,
    ])

    if (fetchStatus === 'loading') {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                >
                    <Typography
                        variant="h4"
                        color={theme.palette.primary.textWhite}
                    >
                        Payments
                    </Typography>
                </Box>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box>
            {createPaymentPopover && (
                <CreatePaymentPopover
                    customerID={customerID}
                    setPopoverVisible={setCreatePaymentPopover}
                    formStatus={formStatus}
                    setFormStatus={setFormStatus}
                    getContractorDashboard={getContractorDashboard}
                />
            )}
            {viewPaymentPopover && (
                <ViewPaymentPopover
                    payment={viewPayment}
                    setPopoverVisible={setViewPaymentPopover}
                />
            )}
            {deletePaymentPopover && (
                <ConfirmDeletePopover
                    setPopoverVisible={setDeletePaymentPopover}
                    deleteMessage={'Are you sure?'}
                    deleteFunction={deletePayment}
                    deleteChargeID={paymentToDelete.payment_id}
                />
            )}
            <Box
                display={'flex'}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Typography
                    variant="h4"
                    color={theme.palette.primary.textWhite}
                >
                    Payments
                </Typography>
            </Box>
            <Box
                display={'flex'}
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Typography
                    variant="h6"
                    color={theme.palette.primary.textWhite}
                    style={{ marginRight: '10px' }}
                >
                    Date Range:
                </Typography>

                <RangePicker onChange={(dates) => setDateRange(dates)} />
                <Box padding>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() =>
                            setCreatePaymentPopover(!createPaymentPopover)
                        }
                    >
                        Add Payment
                    </Button>
                </Box>
            </Box>
            {!filteredPayments.length ? (
                <Typography
                    variant="body1"
                    textAlign={'center'}
                    color={theme.palette.primary.textWhite}
                >
                    No Results Found
                </Typography>
            ) : null}
            {filteredPayments
                ?.sort((a, b) => {
                    return new Date(b.payment_date) - new Date(a.payment_date)
                })
                .map((payment, index) => (
                    <Card
                        key={index}
                        style={{
                            margin: '10px',
                            border: '1px solid black',
                            //hover effect
                        }}
                    >
                        <CardContent
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'lightgray',
                                    cursor: 'pointer',
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                position: 'relative',
                            }}
                        >
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    onClick={() => clickViewPayment(payment)}
                                >
                                    <Typography
                                        variant="h6"
                                        style={{
                                            fontWeight: 'bold',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Payment ID: {payment.payment_id}
                                    </Typography>

                                    <Typography>
                                        Payment Date:{' '}
                                        {moment
                                            .utc(payment.payment_date)
                                            .format('MM/DD/YYYY')}
                                    </Typography>
                                    <Typography>
                                        Payment Method: {payment.payment_method}
                                    </Typography>
                                    <Typography>
                                        Payment Notes: {payment.payment_notes}
                                    </Typography>
                                </Grid>

                                <Grid
                                    container
                                    item
                                    xs={12}
                                    sm={6}
                                    justifyContent={'right'}
                                    alignItems={'flex-end'}
                                >
                                    <Grid item xs={12}>
                                        <Typography
                                            display={'flex'}
                                            flexDirection={'row'}
                                            justifyContent={'right'}
                                            alignItems={'flex-end'}
                                            variant="h6"
                                            style={{
                                                fontWeight: 'bold',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            Payment Amount:{' '}
                                            {new Intl.NumberFormat('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            }).format(payment.payment_amount)}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box
                                            display={'flex'}
                                            flexDirection={'row'}
                                            alignItems={'flex-end'}
                                            justifyContent={'right'}
                                            height={'100%'}
                                        >
                                            <Box padding>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() =>
                                                        handleDeletePayment(
                                                            payment
                                                        )
                                                    }
                                                >
                                                    Delete
                                                </Button>
                                            </Box>

                                            <Box padding>
                                                <Button
                                                    onClick={() =>
                                                        clickViewPayment(
                                                            payment
                                                        )
                                                    }
                                                    variant="contained"
                                                    size="small"
                                                >
                                                    View Payment
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                ))}
        </Box>
    )
}
