import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import NavBar from './utils/Components/SharedOrPublicViews/NavBar/NavBar'
import { Box } from '@mui/system'
import LandingPage from './Pages/LandingPage/LandingPage'
import DashboardDirector from './Dashboards/DashboardDirector/DashboardDirector'
import Footer from './utils/Components/SharedOrPublicViews/Footer/Footer'
import Error404 from './utils/Components/SharedOrPublicViews/ErrorScenarios/404'
import Services from './Pages/Services/Services'
import ContactUs from './utils/Components/SharedOrPublicViews/ContactUs/ContactUs'
import ViewPDFEstimate from './utils/Components/SharedOrPublicViews/GeneratePDFEstimate/ViewPDFEstimate'
import SignUp from './utils/Components/SharedOrPublicViews/SignUp/SignUp'
import OrderService from './utils/Components/SharedOrPublicViews/OrderService/OrderService'
import InteractionPage from './Pages/InteractionPage/InteractionPage'

import theme from './theme'
import SnowplowingPricing from './Pages/SnowplowingPricing/SnowplowingPricing'
import SnowplowingPricingInverGroveHeights from './Pages/SnowplowingPricing/SnowplowingPricingInverGroveHeights'
import SnowplowingPricingSouthStPaul from './Pages/SnowplowingPricing/SnowplowingPricingSouthStPaul'
import SnowplowingPricingWoodbury from './Pages/SnowplowingPricing/SnowplowingPricingWoodbury'
import SaveTheBees from './Pages/SaveTheBees/SaveTheBees'

function App() {
    return (
        <ThemeProvider theme={theme}>
            <script src="https://api.mapbox.com/mapbox-gl-js/v2.14.1/mapbox-gl.js"></script>
            <link
                href="https://api.mapbox.com/mapbox-gl-js/v2.14.1/mapbox-gl.css"
                rel="stylesheet"
            />
            <CssBaseline />
            <Box
                bgcolor={theme.palette.background.default}
                display="flex"
                flexDirection="column"
                minHeight="100vh"
            >
                <BrowserRouter>
                    <Box flexGrow={1}>
                        <NavBar />
                        <Routes>
                            <Route path="/" element={<LandingPage />} />
                            <Route path="/home" element={<LandingPage />} />
                            <Route path="/pricing" element={<OrderService />} />
                            <Route path="/services" element={<Services />} />
                            <Route
                                path="/SnowplowPricing"
                                element={<SnowplowingPricing />}
                            />
                            <Route
                                path="/InverGroveHeights/SnowplowPricing"
                                element={
                                    <SnowplowingPricingInverGroveHeights />
                                }
                            />
                            <Route
                                path="/SouthStPaul/SnowplowPricing"
                                element={<SnowplowingPricingSouthStPaul />}
                            />
                            <Route
                                path="/Woodbury/SnowplowPricing"
                                element={<SnowplowingPricingWoodbury />}
                            />
                            <Route path="/contactUs" element={<ContactUs />} />
                            <Route
                                path="/ViewEstimate/:view_token"
                                element={<ViewPDFEstimate />}
                            />
                            <Route
                                path="/EmailSignUpRegister/:queryString"
                                element={<SignUp />}
                            />
                            <Route
                                path="/orderService"
                                element={<OrderService />}
                            />
                            <Route
                                path="/orderService/:success"
                                element={<OrderService />}
                            />
                            <Route
                                path="/email_engagement_requested"
                                element={<InteractionPage />}
                            />
                            <Route
                                //do not remove this path, it was printed in the 2024 parade marketing materials
                                path="/savethebees"
                                element={<SaveTheBees />}
                            />
                            <Route
                                path="/:component"
                                element={<DashboardDirector />}
                            />
                            <Route
                                path="/:component/:user_id"
                                element={<DashboardDirector />}
                            />
                            <Route
                                path="/:component/:user_id/:detailed_id"
                                element={<DashboardDirector />}
                            />
                            <Route path="*" element={<Error404></Error404>} />
                        </Routes>
                    </Box>
                    <Box flexShrink={0}>
                        <Footer />
                    </Box>
                </BrowserRouter>
            </Box>
        </ThemeProvider>
    )
}

export default App
