//A functional component that will be used for all loading scenarios
//The component should be a styled MUI component with a spinner in the middle
//The component should be imported into all components that have loading scenarios
//

import React, { useEffect } from 'react'
import './Loading.css'
import { CircularProgress, Box, Typography } from '@mui/material'
import { Button } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import Success from '../../FormStatusIcons/Success'

export default function Loading() {
    const { getAccessTokenSilently } = useAuth0()
    const [issueReportedStatus, setIssueReportedStatus] = React.useState('idle')
    const [loadingTime, setLoadingTime] = React.useState(0)

    const submitIssue = (submittedAutomatically) => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_DB}/reportAnIssue`,
                    {
                        issue: 'Loading',
                        page: window.location.href,
                        notes: submittedAutomatically
                            ? `Loading has been going on for ${loadingTime} seconds and was submitted automatically`
                            : `Loading has been going on for ${loadingTime} seconds and was submitted manually`,
                    },

                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        //display a message that the issue was reported
                        setIssueReportedStatus('success')
                    } else {
                        //display a message that the issue was not reported
                        setIssueReportedStatus('error')
                    }
                })
        }
        getJWT()
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (loadingTime === 100) {
                submitIssue(true)
            }

            setLoadingTime((loadingTime) => loadingTime + 1)
        }, 1000)
        return () => clearInterval(interval)
    })

    return (
        <Box height={'100vh'} width={'100vw'}>
            <Box className="loading">
                {loadingTime > 6 && loadingTime <= 12 ? (
                    <Box
                        padding={'4vh'}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Typography
                            variant="h5"
                            component="div"
                            textAlign={'center'}
                            sx={{ flexGrow: 1, fontWeight: 'bold' }}
                        >
                            It's taking longer than expected to load.
                        </Typography>
                        <Typography
                            variant="h6"
                            component="div"
                            textAlign={'center'}
                            sx={{ flexGrow: 1, fontWeight: 'bold' }}
                        >
                            We're still working on it...
                        </Typography>
                    </Box>
                ) : loadingTime > 12 ? (
                    <Box padding={'4vh'}>
                        <Typography
                            variant="h5"
                            component="div"
                            textAlign={'center'}
                            sx={{ flexGrow: 1, fontWeight: 'bold' }}
                        >
                            It's taking longer than expected to load, there
                            might be an issue.
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={
                                issueReportedStatus === 'success' ||
                                issueReportedStatus === 'error'
                            }
                            //onclick report issue
                            onClick={() => {
                                submitIssue()
                            }}
                        >
                            Report Problem
                        </Button>
                        {issueReportedStatus === 'success' && (
                            <Success title={'Problem reported successfully'} />
                        )}
                    </Box>
                ) : null}
                <CircularProgress />
            </Box>
        </Box>
    )
}
