/*

A customer table that will take in a list of customerID's and display 
them in a table. The table will have a column for each of the following:
- Customer ID
- Customer Name
- Customer Address
- Customer Email
- Customer Balance
- Customer Messages
- Open Jobs


Import fonts from Google Fonts and use them for:
- Customer Name
- Customer Address
- Customer Email


Import the customerTable.css file into this file.


*/

import React, { useEffect } from 'react'
import './customerTable.css'
import { Typography, Box, Tooltip, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { CheckBox, NavigateNext, NoAccounts } from '@mui/icons-material'
import { Grid } from '@mui/material'

export default function CustomerTable(props) {
    const {
        customers,
        customerSearch,
        searchActiveCustomers,
        searchPastDueInvoices,
        searchMonthlyCustomers,
        searchPendingJobs,
        searchPendingCharges,
        setToCustomerDetail,
    } = props

    const theme = useTheme()

    const [filteredCustomers, setFilteredCustomers] = React.useState({})

    const checkUsersJobs = (user, status) => {
        let jobs = 0
        if (user.jobs) {
            Object.keys(user.jobs).forEach((key) => {
                if (user.jobs[key].job_status === status) {
                    jobs++
                }
            })
        }
        if (jobs > 0) {
            return true
        }
        return false
    }

    const checkUserInvoices = (user) => {
        let invoices = 0
        if (user.invoices) {
            Object.keys(user.invoices).forEach((key) => {
                if (user.invoices[key].invoice_status === 'unpaid') {
                    //if the invoice is unpaid and past due
                    if (
                        new Date(user.invoices[key].due_date) <
                        new Date(Date.now())
                    ) {
                        invoices++
                    }
                }
            })
        }
        if (invoices > 0) {
            return true
        }
        return false
    }

    const checkMonthlyCustomers = (user) => {
        if (user?.pricing?.monthly) {
            return true
        }
        return false
    }

    const checkPendingCharges = (user) => {
        let charges = 0

        if (user?.charges) {
            Object.keys(user.charges).forEach((key) => {
                if (user.charges[key].charge_status === 'pending') {
                    charges++
                }
            })
        }

        if (charges > 0) {
            return true
        }
        return false
    }

    useEffect(() => {
        const filterCustomers = () => {
            const filters = []

            if (searchActiveCustomers) {
                filters.push((customer) =>
                    checkUsersJobs(customer, 'In Progress')
                )
            }
            if (searchPendingJobs) {
                filters.push((customer) => checkUsersJobs(customer, 'Pending'))
            }
            if (searchPastDueInvoices) {
                filters.push((customer) => checkUserInvoices(customer))
            }
            if (searchMonthlyCustomers) {
                filters.push((customer) => checkMonthlyCustomers(customer))
            }
            if (searchPendingCharges) {
                filters.push((customer) => checkPendingCharges(customer))
            }

            if (customerSearch) {
                const searchLower = customerSearch.toLowerCase()
                filters.push(
                    (customer) =>
                        customer.first_name
                            .toLowerCase()
                            .includes(searchLower) ||
                        customer.last_name
                            .toLowerCase()
                            .includes(searchLower) ||
                        customer.phone_number
                            .toLowerCase()
                            .includes(searchLower) ||
                        customer.address.toLowerCase().includes(searchLower)
                )
            }

            const filteredCustomers = Object.keys(customers).reduce(
                (acc, key) => {
                    const customer = customers[key]
                    const matchesAllFilters = filters.every((filter) =>
                        filter(customer)
                    )
                    if (matchesAllFilters) {
                        acc[key] = customer
                    }
                    return acc
                },
                {}
            )

            setFilteredCustomers(filteredCustomers)
        }

        filterCustomers()
    }, [
        customerSearch,
        searchActiveCustomers,
        searchPendingJobs,
        searchPastDueInvoices,
        searchMonthlyCustomers,
        searchPendingCharges,
        customers,
    ])

    return (
        <Box>
            {Object.keys(filteredCustomers).length === 0 ? (
                <Grid item xs={12}>
                    <Box
                        padding={2}
                        display="flex"
                        justifyContent="center"
                        width={'100%'}
                    >
                        <Typography
                            variant="body1"
                            color={theme.palette.primary.textWhite}
                        >
                            No results found
                        </Typography>
                    </Box>
                </Grid>
            ) : (
                Object.keys(filteredCustomers).map((key) => {
                    return (
                        <Grid
                            container
                            key={key}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            backgroundColor={
                                theme.palette.primary.supportingWhite
                            }
                            borderRadius={1}
                            marginBottom={1}
                        >
                            <Grid item xs={12} md={8}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    width={'100%'}
                                    sx={{
                                        '&:hover': {
                                            cursor: 'pointer',
                                        },
                                    }}
                                    onClick={() => {
                                        setToCustomerDetail(customers[key])
                                    }}
                                >
                                    {customers[key]
                                        .has_finished_registration ? (
                                        <Box padding={1}>
                                            <Tooltip title="Registered">
                                                <CheckBox
                                                    sx={{
                                                        color: theme.palette
                                                            .primary
                                                            .successGreen,
                                                    }}
                                                />
                                            </Tooltip>
                                        </Box>
                                    ) : (
                                        <Box padding={1}>
                                            <Tooltip title="Not Registered">
                                                <NoAccounts
                                                    sx={{
                                                        color: theme.palette
                                                            .primary.errorRed,
                                                    }}
                                                />
                                            </Tooltip>
                                        </Box>
                                    )}
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="left"
                                    >
                                        <Typography variant="body1">
                                            {customers[key].first_name &&
                                            customers[key].last_name
                                                ? customers[key].first_name +
                                                  ' ' +
                                                  customers[key].last_name
                                                : 'No Name Found'}
                                        </Typography>
                                        <Typography variant="body2">
                                            {customers[key].address}
                                        </Typography>
                                        <Typography variant="body2">
                                            {customers[key].email}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="right"
                                    padding={1}
                                >
                                    <Box padding>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            sx={{
                                                backgroundColor:
                                                    theme.palette.primary
                                                        .backup,
                                                color: theme.palette.primary
                                                    .textWhite,
                                            }}
                                            onClick={() => {
                                                window.open(
                                                    `http://maps.apple.com/?daddr=${customers[key].address}`
                                                )
                                            }}
                                        >
                                            Directions
                                        </Button>
                                    </Box>
                                    <Box padding>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            sx={{
                                                backgroundColor:
                                                    theme.palette.primary
                                                        .backup,
                                                color: theme.palette.primary
                                                    .textWhite,
                                                borderRadius: '50%',
                                                minWidth: '30px',
                                                width: '30px',
                                                height: '30px',
                                                padding: '0',
                                            }}
                                            onClick={() => {
                                                setToCustomerDetail(
                                                    customers[key]
                                                )
                                            }}
                                        >
                                            <NavigateNext />
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    )
                })
            )}
            <Box
                width={'100%'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Typography
                    variant="h6"
                    color={theme.palette.primary.textWhite}
                >
                    Showing {Object.keys(filteredCustomers)?.length}{' '}
                    {Object.keys(filteredCustomers)?.length === 1
                        ? 'Customer'
                        : 'Customers'}
                </Typography>
            </Box>
        </Box>
    )
}
