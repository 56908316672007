//This will be the component that gets displayed on the customer interactions tab
//when the user is a contractor and clicks on the create estimate button.
//It should collect all the necessary information to create an estimate and then send that information to the backend to be stored in the database.
//The user should be able to add as many charges as they want to the estimate.
//The customer is the customer that the estimate is being created for.
//The user should be able to add a description and an amount for each charge.
//The user should also be able to set an expiration date for the estimate.

import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import { Button, Checkbox, TextField, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { InputLabel } from '@mui/material'

import Success from '../../FormStatusIcons/Success'
import dayjs from 'dayjs'
import AddIcon from '@mui/icons-material/Add'
import { useTheme } from '@mui/material/styles'

export default function CreateEstimate(props) {
    const { customerID, setCreateEstimatePopoverVisible } = props
    const { getAccessTokenSilently } = useAuth0()
    const [charges, setCharges] = useState([{ description: '', amount: 0 }])
    const [notes, setNotes] = useState('')
    const [expirationDate, setExpirationDate] = useState(dayjs(new Date()))
    const [formStatus, setFormStatus] = useState('idle')
    const [send_email_notification, setSendEmailNotification] = useState(false)
    const [newCharge] = useState({ description: '', amount: 0 })
    const theme = useTheme()
    const { user } = useAuth0()

    const addCharge = () => {
        setCharges([...charges, newCharge])
    }

    const removeCharge = (index) => {
        const newCharges = charges.filter((charge, i) => i !== index)
        setCharges(newCharges)
    }

    const handleSubmit = async () => {
        setFormStatus('loading')
        const accessToken = await getAccessTokenSilently()
        const estimate = {
            user_id: customerID,
            date_generated: dayjs(new Date()),
            expiration_date: expirationDate,
            estimate_date: dayjs(new Date()),
            send_email_notification,
            created_by: user?.sub,
            notes: notes,
            charges,
        }

        axios
            .post(
                `${process.env.REACT_APP_BACKEND_DB}/createEstimate`,
                estimate,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then((response) => {
                setFormStatus('success')
                setTimeout(() => {
                    setFormStatus('idle')
                    setCreateEstimatePopoverVisible(false)
                }, 1000)
            })
    }

    return (
        <Box
            width={'100%'}
            height={'100%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Box textAlign={'center'}>
                <Box mb={'2.5vh'}>
                    <Typography variant="h3"> Create Estimate </Typography>
                </Box>
            </Box>
            <Box>
                <Box padding={1}>
                    <InputLabel> Expiration Date </InputLabel>
                    <DatePicker
                        value={expirationDate}
                        onChange={(newValue) => setExpirationDate(newValue)}
                    />
                </Box>
                <Box padding={1} borderRadius={5}>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        padding={1}
                    >
                        <InputLabel> Charges </InputLabel>
                        <Tooltip title="Add Charge">
                            <AddIcon
                                onClick={addCharge}
                                sx={{
                                    '&:hover': {
                                        color: theme.palette.primary.textWhite,
                                        cursor: 'pointer',
                                    },
                                }}
                            />
                        </Tooltip>
                    </Box>
                    {charges.map((charge, index) => (
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            width={'100%'}
                            key={index}
                            padding={1}
                        >
                            <Box paddingRight={1}>
                                <TextField
                                    label="Description"
                                    value={charge.description}
                                    onChange={(e) => {
                                        const newCharges = charges.map(
                                            (c, i) =>
                                                i === index
                                                    ? {
                                                          ...c,
                                                          description:
                                                              e.target.value,
                                                      }
                                                    : c
                                        )
                                        setCharges(newCharges)
                                    }}
                                />
                            </Box>
                            <Box paddingLeft={1}>
                                <TextField
                                    type="number"
                                    placeholder="$0.00"
                                    onChange={(e) =>
                                        setCharges(
                                            charges.map((c, i) =>
                                                i === index
                                                    ? {
                                                          ...c,
                                                          amount: parseFloat(
                                                              e.target.value
                                                          ),
                                                      }
                                                    : c
                                            )
                                        )
                                    }
                                />
                            </Box>
                            {charges.length > 1 && (
                                <Box padding={1}>
                                    <Button onClick={() => removeCharge(index)}>
                                        {' '}
                                        Remove{' '}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    ))}
                </Box>
                <Box
                    padding={2}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <TextField
                        fullWidth
                        multiline
                        rows={2}
                        label="Estimate Notes"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                    />
                </Box>

                <Box
                    padding={1}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    {
                        //add in a checkbox for the user to select if they want to send the estimate to the customer via email
                    }

                    <InputLabel> Send Estimate to Customer </InputLabel>
                    <Checkbox
                        color="primary"
                        value={send_email_notification}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        onChange={(e) =>
                            setSendEmailNotification(e.target.checked)
                        }
                    />
                </Box>
                <Box>
                    <Button onClick={handleSubmit}> Submit </Button>
                </Box>
                <Box>
                    {formStatus === 'success' && (
                        <Success title="Estimate Created Successfully" />
                    )}
                </Box>
            </Box>
        </Box>
    )
}
