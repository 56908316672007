//This will be a simple search bar that will be used to search for customers
//It will be used in the contractor dashboard
//it will take in a prop called setCustomerSearch which will be a function that will be used to set the customer search
//it will also take in a prop called customerSearch which will be the current customer search
//It should use MUI components

import React from 'react'

import { Box, Grid, TextField } from '@mui/material'
import { FormControlLabel } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Checkbox from '@mui/material/Checkbox'

export default function CustomerSearch(props) {
    const theme = useTheme()
    const {
        searchActiveCustomers,
        setSearchActiveCustomers,
        searchPendingJobs,
        setSearchPendingJobs,
        searchPastDueInvoices,
        setSearchPastDueInvoices,
        customerSearch,
        setCustomerSearch,
        searchMonthlyCustomers,
        setSearchMonthlyCustomers,
        searchPendingCharges,
        setSearchPendingCharges,
    } = props

    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            backgroundColor={theme.palette.primary.textWhite}
            borderRadius={1}
        >
            <Grid
                container
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                padding={2}
            >
                <Grid item xs={5} md={4}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={searchActiveCustomers}
                                        style={{
                                            color: theme.palette.primary.backup,
                                        }}
                                        onChange={(event) =>
                                            setSearchActiveCustomers(
                                                event.target.checked
                                            )
                                        }
                                        name="searchActiveCustomers"
                                        color="primary"
                                    />
                                }
                                label="Active Jobs"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={searchPendingJobs}
                                        style={{
                                            color: theme.palette.primary.backup,
                                        }}
                                        onChange={(event) =>
                                            setSearchPendingJobs(
                                                event.target.checked
                                            )
                                        }
                                        name="searchPendingJobs"
                                        color="primary"
                                    />
                                }
                                label="Pending Jobs"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={7} md={8}>
                    <Grid container>
                        <Grid item xs={12} md={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={searchMonthlyCustomers}
                                        style={{
                                            color: theme.palette.primary.backup,
                                        }}
                                        onChange={(event) =>
                                            setSearchMonthlyCustomers(
                                                event.target.checked
                                            )
                                        }
                                        name="searchMonthlyCustomers"
                                        color="primary"
                                    />
                                }
                                label="Monthly Customers"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={searchPendingCharges}
                                        style={{
                                            color: theme.palette.primary.backup,
                                        }}
                                        onChange={(event) =>
                                            setSearchPendingCharges(
                                                event.target.checked
                                            )
                                        }
                                        name="searchPendingCharges"
                                        color="primary"
                                    />
                                }
                                label="Pending Charges"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={searchPastDueInvoices}
                                        style={{
                                            color: theme.palette.primary.backup,
                                        }}
                                        onChange={(event) =>
                                            setSearchPastDueInvoices(
                                                event.target.checked
                                            )
                                        }
                                        name="searchPastDueInvoices"
                                        color="primary"
                                    />
                                }
                                label="Past Due Invoices"
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id="outlined-basic"
                        label="Search Customers"
                        variant="outlined"
                        fullWidth
                        onChange={(event) =>
                            setCustomerSearch(event.target.value)
                        }
                        value={customerSearch}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
