//Invoices component

//This is the invoices component. It will be imported into the customer dashboard.

import React, { useState } from 'react'
import axios from 'axios'
import { Typography } from '@mui/material'

import { CircularProgress } from '@mui/material'

import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router'
import { useAuth0 } from '@auth0/auth0-react'
import moment from 'moment'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import CreateInvoicePopover from '../../../modals/CreateInvoicePopover/CreateInvoicePopover'
import MarkAsPaidLogInvoicePromptPopover from '../../../modals/MarkAsPaidLogInvoicePromptPopover/MarkAsPaidLogInvoicePromptPopover'
import ConfirmCancelPopover from '../../../modals/ConfirmCancelPopover/ConfirmCancelPopover'

export default function Invoices(props) {
    const { getAccessTokenSilently } = useAuth0()
    const {
        isLoading,
        customerDetail,
        createInvoicePopover,
        setCreateInvoicePopover,
        getContractorDashboard,
    } = props

    const [
        markAsPaidLogInvoicePromptPopover,
        setMarkAsPaidLogInvoicePromptPopover,
    ] = useState(false)
    const [cancelPopoverVisible, setCancelPopoverVisible] = useState(false)
    const [cancelInvoice, setCancelInvoice] = useState('')

    const [invoiceToEdit, setInvoiceToEdit] = useState('')
    const [paidFilter, setPaidFilter] = useState('all')

    const handleFilterChange = (event, newFilter) => {
        setPaidFilter(newFilter)
    }

    const filteredInvoices = customerDetail?.invoices?.filter((invoice) => {
        if (paidFilter === 'all') {
            return invoice
        } else if (paidFilter === 'paid') {
            return invoice.invoice_status === 'paid'
        } else if (paidFilter === 'unpaid') {
            return invoice.invoice_status === 'unpaid'
        }
        return null
    })

    const theme = useTheme()
    const navigate = useNavigate()

    const updateInvoiceToPaid = (invoice) => {
        setInvoiceToEdit(invoice)
        setMarkAsPaidLogInvoicePromptPopover(!markAsPaidLogInvoicePromptPopover)
    }

    const updateInoviceToCancelled = (invoice) => {
        setCancelInvoice(invoice)
        setCancelPopoverVisible(!cancelPopoverVisible)
    }

    const sendReminderEmail = async (invoice) => {
        const accessToken = await getAccessTokenSilently()

        axios
            .post(
                `${process.env.REACT_APP_BACKEND_DB}/sendReminderEmail`,
                {
                    user_id: customerDetail.user_id,
                    invoice_id: invoice.invoice_id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then(() => {
                getContractorDashboard()
            })
    }

    const cancelAnInvoice = () => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_DB}/cancelInvoice`,
                    {
                        invoice_id: cancelInvoice.invoice_id,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then(() => {
                    getContractorDashboard()
                })
        }
        getJWT()
    }

    if (isLoading) {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                >
                    <Typography
                        variant="h4"
                        color={theme.palette.primary.textWhite}
                    >
                        Invoices
                    </Typography>
                </Box>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box width={'100%'}>
            {cancelPopoverVisible && (
                <ConfirmCancelPopover
                    setPopoverVisible={setCancelPopoverVisible}
                    cancelMessage={'Are you sure?'}
                    cancelFunction={cancelAnInvoice}
                    cancelInvoiceID={cancelInvoice}
                />
            )}
            {createInvoicePopover && (
                <CreateInvoicePopover
                    customerDetail={customerDetail}
                    createInvoicePopover={createInvoicePopover}
                    setCreateInvoicePopover={setCreateInvoicePopover}
                />
            )}
            {markAsPaidLogInvoicePromptPopover && (
                <MarkAsPaidLogInvoicePromptPopover
                    setPopoverVisible={setMarkAsPaidLogInvoicePromptPopover}
                    customerID={customerDetail.user_id}
                    invoiceToEdit={invoiceToEdit}
                    getContractorDashboard={getContractorDashboard}
                    andMarkPaid={updateInvoiceToPaid}
                />
            )}
            <Box>
                <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                >
                    <Typography
                        variant="h4"
                        color={theme.palette.primary.textWhite}
                    >
                        Invoices
                    </Typography>
                </Box>
                <Box
                    display={'flex'}
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                >
                    <Typography
                        variant="h6"
                        color={theme.palette.primary.textWhite}
                        style={{ marginRight: '10px' }}
                    >
                        Filter by:
                    </Typography>

                    <ToggleButtonGroup
                        value={paidFilter}
                        exclusive
                        onChange={handleFilterChange}
                        style={{ backgroundColor: 'black' }}
                    >
                        <ToggleButton
                            value="all"
                            style={{
                                backgroundColor:
                                    paidFilter === 'all' ? 'gray' : 'black',
                                color: paidFilter === 'all' ? 'white' : 'gray',
                            }}
                        >
                            All
                        </ToggleButton>
                        <ToggleButton
                            value="paid"
                            style={{
                                backgroundColor:
                                    paidFilter === 'paid' ? 'gray' : 'black',
                                color: paidFilter === 'paid' ? 'white' : 'gray',
                            }}
                        >
                            Paid
                        </ToggleButton>
                        <ToggleButton
                            value="unpaid"
                            style={{
                                backgroundColor:
                                    paidFilter === 'unpaid' ? 'gray' : 'black',
                                color:
                                    paidFilter === 'unpaid' ? 'white' : 'gray',
                            }}
                        >
                            Unpaid
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Box style={{ marginLeft: '10px' }}>
                        <Button
                            variant="contained"
                            size={'small'}
                            onClick={() => setCreateInvoicePopover(true)}
                        >
                            Create Invoice
                        </Button>
                    </Box>
                </Box>
                {!filteredInvoices.length ? (
                    <Typography
                        variant="body1"
                        textAlign={'center'}
                        color={theme.palette.primary.textWhite}
                    >
                        No Results Found
                    </Typography>
                ) : null}

                {filteredInvoices
                    ?.sort((a, b) => {
                        return (
                            new Date(b.date_generated) -
                            new Date(a.date_generated)
                        )
                    })
                    .map((invoice, index) => (
                        <Card
                            key={index}
                            style={{
                                margin: '10px',
                                padding: '10px',
                                border: '1px solid black',
                                //hover effect
                            }}
                        >
                            <CardContent
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'lightgray',
                                        cursor: 'pointer',
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    padding: '10px',
                                    position: 'relative',
                                }}
                            >
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        onClick={() =>
                                            navigate(
                                                `/pdf/${invoice.user_id}/${invoice.invoice_id}`
                                            )
                                        }
                                    >
                                        <Typography
                                            variant="h6"
                                            style={{
                                                fontWeight: 'bold',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            Invoice #{invoice.invoice_id}
                                        </Typography>
                                        <Typography>
                                            Date Generated:{' '}
                                            {moment
                                                .utc(invoice.date_generated)
                                                .format('MM/DD/YYYY')}
                                        </Typography>
                                        <Typography>
                                            Due Date:{' '}
                                            {moment
                                                .utc(invoice.due_date)
                                                .format('MM/DD/YYYY')}
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        sm={6}
                                        justifyContent={'right'}
                                        alignItems={'flex-end'}
                                    >
                                        <Grid item xs={12}>
                                            <Typography
                                                display={'flex'}
                                                flexDirection={'row'}
                                                justifyContent={'right'}
                                                alignItems={'flex-end'}
                                                variant="h6"
                                                style={{
                                                    fontWeight: 'bold',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                Total Amount:{' '}
                                                {new Intl.NumberFormat(
                                                    'en-US',
                                                    {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                    }
                                                ).format(invoice.total_amount)}
                                            </Typography>
                                            <Box
                                                display={'flex'}
                                                flexDirection={'row'}
                                                alignItems={'center'}
                                                justifyContent={'flex-end'}
                                            >
                                                <Typography paddingRight={1}>
                                                    Invoice Status:{' '}
                                                </Typography>
                                                <Typography
                                                    display={'flex'}
                                                    flexDirection={'row'}
                                                    justifyContent={'right'}
                                                    alignItems={'flex-end'}
                                                >
                                                    {invoice.invoice_status ===
                                                    'paid' ? (
                                                        <span
                                                            style={{
                                                                color: theme
                                                                    .palette
                                                                    .primary
                                                                    .successGreen,
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '1.2em',
                                                            }}
                                                        >
                                                            [PAID]
                                                        </span>
                                                    ) : invoice.invoice_status ===
                                                          'unpaid' &&
                                                      moment
                                                          .utc(invoice.due_date)
                                                          .isBefore(
                                                              moment.utc(
                                                                  new Date()
                                                              )
                                                          ) ? (
                                                        <span
                                                            style={{
                                                                color: theme
                                                                    .palette
                                                                    .primary
                                                                    .errorRed,
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '1.2em',
                                                            }}
                                                        >
                                                            [OVERDUE]
                                                        </span>
                                                    ) : invoice.invoice_status ===
                                                      'unpaid' ? (
                                                        <span
                                                            style={{
                                                                color: theme
                                                                    .palette
                                                                    .primary
                                                                    .cautionYellow,
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '1.2em',
                                                            }}
                                                        >
                                                            [PENDING]
                                                        </span>
                                                    ) : invoice.invoice_status ===
                                                      'cancelled' ? (
                                                        <span
                                                            style={{
                                                                color: theme
                                                                    .palette
                                                                    .primary
                                                                    .errorRed,
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '1.2em',
                                                            }}
                                                        >
                                                            [CANCELLED]
                                                        </span>
                                                    ) : null}
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        {invoice.invoice_status ===
                                        'paid' ? null : (
                                            <Grid item xs={12}>
                                                <Box
                                                    display={'flex'}
                                                    flexDirection={'row'}
                                                    alignItems={'flex-end'}
                                                    justifyContent={'right'}
                                                    height={'100%'}
                                                >
                                                    <Box padding>
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            onClick={() =>
                                                                updateInvoiceToPaid(
                                                                    invoice
                                                                )
                                                            }
                                                            disabled={
                                                                invoice.invoice_status ===
                                                                    'paid' ||
                                                                invoice.invoice_status ===
                                                                    'cancelled'
                                                            }
                                                        >
                                                            Mark as Paid
                                                        </Button>
                                                    </Box>
                                                    <Box padding>
                                                        {invoice.reminder_email_sent ? (
                                                            <Button
                                                                onClick={() =>
                                                                    sendReminderEmail(
                                                                        invoice
                                                                    )
                                                                }
                                                                variant="contained"
                                                                size="small"
                                                                disabled
                                                            >
                                                                Reminded
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                onClick={() =>
                                                                    sendReminderEmail(
                                                                        invoice
                                                                    )
                                                                }
                                                                variant="contained"
                                                                size="small"
                                                                disabled={
                                                                    invoice.invoice_status ===
                                                                    'cancelled'
                                                                }
                                                            >
                                                                Remind
                                                            </Button>
                                                        )}
                                                    </Box>
                                                    <Box padding>
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            onClick={() =>
                                                                updateInoviceToCancelled(
                                                                    invoice
                                                                )
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ))}
            </Box>
        </Box>
    )
}
