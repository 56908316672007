//This is the page that will display a sinlge route. It will display the following information:
// - Route Name
// - Route Description
// - The route status

import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@mui/system'
import { Grid, TableContainer, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Table, TableBody, TableCell, TableRow, TableHead } from '@mui/material'
import ScheduleIcon from '@mui/icons-material/Schedule'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Button from '@mui/material/Button'
import Success from '../../FormStatusIcons/Success'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

export default function SingleRoute(props) {
    const { getAccessTokenSilently } = useAuth0()
    const theme = useTheme()
    const location = useLocation()
    //route comes from props
    const { route, enterRouteInProgress, setBackToRoutes } = props
    const [startingRoute, setStartingRoute] = useState()

    const startRoute = async () => {
        //TODO: Update the route status to 'Active'
        setStartingRoute('starting')
        const accessToken = await getAccessTokenSilently()
        axios
            .post(
                `${process.env.REACT_APP_BACKEND_DB}/updateRouteStatus`,
                {
                    route_id: route.route_id,
                    route_status: 'In Progress',
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then((response) => {
                setStartingRoute('started')
                setTimeout(() => {
                    setStartingRoute('idle')
                    enterRouteInProgress(route)
                }, 1500)
            })
    }

    const completeRoute = async () => {
        setStartingRoute('completing')
        //update the route status to complete

        const accessToken = await getAccessTokenSilently()
        axios
            .post(
                `${process.env.REACT_APP_BACKEND_DB}/updateRouteToCompleted`,
                {
                    route_id: route.route_id,
                },

                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then((response) => {
                setStartingRoute('completed')
                setTimeout(() => {
                    setStartingRoute('idle')
                    setBackToRoutes()
                }, 1000)
            })
    }

    const resetToScheduled = async () => {
        //TODO: Update the route status to 'Active'
        setStartingRoute('reseting')
        const accessToken = await getAccessTokenSilently()
        axios
            .post(
                `${process.env.REACT_APP_BACKEND_DB}/updateRouteStatus`,
                {
                    route_id: route.route_id,
                    route_status: 'Scheduled',
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then((response) => {
                setStartingRoute('reset')
                setTimeout(() => {
                    setStartingRoute('idle')
                }, 1500)
            })
    }

    const cancelRoute = async () => {
        //TODO: Update the route status to 'Active'
        setStartingRoute('cancelling')
        const accessToken = await getAccessTokenSilently()
        axios
            .post(
                `${process.env.REACT_APP_BACKEND_DB}/updateRouteStatus`,
                {
                    route_id: route.route_id,
                    route_status: 'Cancelled',
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then((response) => {
                setStartingRoute('cancelled')
                setTimeout(() => {
                    setStartingRoute('idle')
                    setBackToRoutes()
                }, 1500)
            })
    }

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: '/routeDetail',
            title: 'Route Detail',
        })
    }, [location])

    return (
        <Box padding={2}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Box padding={'2vh'} width={'100%'}>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: theme.palette.primary.textWhite,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {route?.route_name}
                                </Typography>

                                <Typography
                                    variant="h4"
                                    sx={{
                                        flexGrow: 1,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {route?.route_status === 'Active' ? (
                                        <Tooltip title="Active">
                                            <AutorenewIcon
                                                sx={{
                                                    color: theme.palette.primary
                                                        .successGreen,
                                                }}
                                            />
                                        </Tooltip>
                                    ) : route?.route_status === 'Scheduled' ? (
                                        <Tooltip title="Scheduled">
                                            <ScheduleIcon
                                                sx={{
                                                    color: theme.palette.primary
                                                        .warningYellow,
                                                }}
                                            />
                                        </Tooltip>
                                    ) : route?.route_status === 'Cancelled' ||
                                      route?.route_status === 'cancelled' ? (
                                        <Tooltip title="Cancelled">
                                            <CancelIcon
                                                sx={{
                                                    color: theme.palette.primary
                                                        .errorRed,
                                                }}
                                            />
                                        </Tooltip>
                                    ) : route?.route_status === 'Completed' ? (
                                        <Tooltip title="Completed">
                                            <CheckCircleIcon
                                                sx={{
                                                    color: theme.palette.primary
                                                        .successGreen,
                                                }}
                                            />
                                        </Tooltip>
                                    ) : null}
                                </Typography>
                            </Box>

                            <Box>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    //if route completed, display a checkmark
                                                    route?.route_status ===
                                                    'Completed' ? (
                                                        <TableCell>
                                                            Stop Status
                                                        </TableCell>
                                                    ) : null
                                                }

                                                <TableCell
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        color: theme.palette
                                                            .primary.textWhite,
                                                    }}
                                                >
                                                    Customer Name
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        color: theme.palette
                                                            .primary.textWhite,
                                                    }}
                                                >
                                                    Customer Address
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                //map through the route customers and display them in a table but keep them in order

                                                Object.keys(
                                                    route?.route_customers || []
                                                ).map((key) => {
                                                    return (
                                                        <TableRow
                                                            key={
                                                                route
                                                                    ?.route_customers[
                                                                    key
                                                                ]?.user_id
                                                            }
                                                        >
                                                            {route?.route_status ===
                                                            'Completed' ? (
                                                                <TableCell
                                                                    sx={{
                                                                        color: theme
                                                                            .palette
                                                                            .primary
                                                                            .textWhite,
                                                                    }}
                                                                >
                                                                    {
                                                                        //if the customer is in the completed_stops array, display a checkmark
                                                                        route?.finished_stops?.includes(
                                                                            route
                                                                                ?.route_customers[
                                                                                key
                                                                            ]
                                                                                ?.user_id
                                                                        ) ? (
                                                                            <Tooltip
                                                                                title="Completed"
                                                                                placement="top"
                                                                            >
                                                                                <CheckCircleIcon
                                                                                    sx={{
                                                                                        color: theme
                                                                                            .palette
                                                                                            .primary
                                                                                            .successGreen,
                                                                                    }}
                                                                                />
                                                                            </Tooltip>
                                                                        ) : null
                                                                    }
                                                                    {route?.skipped_stops?.includes(
                                                                        route
                                                                            ?.route_customers[
                                                                            key
                                                                        ]
                                                                            ?.user_id
                                                                    ) ? (
                                                                        <Tooltip
                                                                            title="Skipped"
                                                                            placement="top"
                                                                        >
                                                                            <CancelIcon
                                                                                sx={{
                                                                                    color: theme
                                                                                        .palette
                                                                                        .primary
                                                                                        .errorRed,
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                    ) : null}
                                                                </TableCell>
                                                            ) : null}

                                                            <TableCell
                                                                sx={{
                                                                    color: theme
                                                                        .palette
                                                                        .primary
                                                                        .textWhite,
                                                                }}
                                                            >
                                                                {route
                                                                    ?.route_customers[
                                                                    key
                                                                ]?.first_name +
                                                                    ' ' +
                                                                    route
                                                                        ?.route_customers[
                                                                        key
                                                                    ]
                                                                        ?.last_name}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    color: theme
                                                                        .palette
                                                                        .primary
                                                                        .textWhite,
                                                                }}
                                                            >
                                                                {
                                                                    route
                                                                        ?.route_customers[
                                                                        key
                                                                    ]?.address
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                {startingRoute === 'started' ? (
                                    <Success title={'Route Started'} />
                                ) : startingRoute === 'cancelled' ? (
                                    <Success title={'Route Cancelled'} />
                                ) : startingRoute === 'reset' ? (
                                    <Success title={'Route Reset'} />
                                ) : startingRoute === 'completed' ? (
                                    <Success title={'Route Completed'} />
                                ) : null}
                            </Box>
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                {route?.route_status === 'In Progress' ? (
                                    <Box
                                        padding={2}
                                        display={'flex'}
                                        flexDirection={'row'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <Box padding={2}>
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    enterRouteInProgress(route)
                                                }}
                                            >
                                                Enter Route
                                            </Button>
                                        </Box>

                                        <Box padding={2}>
                                            <Button
                                                variant="contained"
                                                onClick={completeRoute}
                                                sx={{
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .successGreen,
                                                }}
                                            >
                                                Complete Route
                                            </Button>
                                        </Box>
                                    </Box>
                                ) : route?.route_status === 'Cancelled' ? (
                                    <Box padding={'1vh'}>
                                        <Button
                                            variant="contained"
                                            onClick={resetToScheduled}
                                            sx={{
                                                backgroundColor:
                                                    theme.palette.primary
                                                        .warningYellow,
                                            }}
                                        >
                                            Reset to Scheduled
                                        </Button>
                                    </Box>
                                ) : route?.route_status === 'Scheduled' ? (
                                    <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <Box padding={'1vh'}>
                                            <Button
                                                variant="contained"
                                                onClick={startRoute}
                                                sx={{
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .main,
                                                }}
                                            >
                                                Start Route
                                            </Button>
                                        </Box>
                                        <Box padding={'1vh'}>
                                            <Button
                                                variant="contained"
                                                onClick={completeRoute}
                                                sx={{
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .successGreen,
                                                }}
                                            >
                                                Complete Route
                                            </Button>
                                        </Box>
                                        <Box padding={'1vh'}>
                                            <Button
                                                variant="contained"
                                                onClick={cancelRoute}
                                                sx={{
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .errorRed,
                                                }}
                                            >
                                                Cancel Route
                                            </Button>
                                        </Box>
                                    </Box>
                                ) : null}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}
