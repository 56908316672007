import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
    Box,
    CircularProgress,
    Grid,
    Typography,
    useTheme,
} from '@mui/material'
import { Helmet } from 'react-helmet'
import { useAuth0 } from '@auth0/auth0-react'
import {
    PersonAddAlt1,
    NoteAdd,
    PeopleOutline,
    Work,
    Paid,
    CreditCard,
    ConnectWithoutContact,
    ArrowDownward,
} from '@mui/icons-material'
import Badge from '@mui/material/Badge'
import RouteIcon from '@mui/icons-material/Route'
import axios from 'axios'
import AddUserPopover from '../../utils/modals/addUserPopover/addUserPopover'
import AddUserChargeInvoicePopover from '../../utils/modals/addUserChargeInvoicePopover/addUserChargeInvoicePopover'
import LinkAccountsPopover from '../../utils/modals/LinkAccountsPopover/LinkAccountsPopover'
import CustomerSearch from '../../utils/Components/ContractorViews/CustomerSearch/CustomerSearch'
import CustomerInfoEditable from '../../utils/Components/CustomerViews/CustomerInfoEditable/CustomerInfoEditable'
import Messages from '../../utils/Components/ContractorViews/Messages/Messages'
import Routes from '../../utils/Components/ContractorViews/Routes/Routes'
import CustomerTable from './CustomerTable/customerTable'
import CurrencyFormat from 'react-currency-format'
import { AccountBox, Message } from '@mui/icons-material'
import { Paper } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import Charges from '../../utils/Components/ContractorViews/Charges/Charges'
import Payments from '../../utils/Components/ContractorViews/Payments/Payments'
import Jobs from '../../utils/Components/ContractorViews/Jobs/Jobs'
import Interactions from '../../utils/Components/ContractorViews/Interactions/Interactions'
import SingleRoute from '../../utils/Components/ContractorViews/Routes/SingleRoute'
import RouteInProgress from '../../utils/Components/ContractorViews/Routes/RouteInProgress'

import { ListItemButton, ListItemText, List, ListItem } from '@mui/material'
import { ArrowDropUp } from '@mui/icons-material'
import Invoices from '../../utils/Components/ContractorViews/Invoices/Invoices'
import CustomerPricing from './CustomerPricing/CustomerPricing'
import CustomerInfoContractorView from '../../utils/Components/ContractorViews/CustomerInfoContractorView/CustomerInfoContractorView'
import CreateANewRoute from '../../utils/Components/ContractorViews/Routes/CreateANewRoute'
import CustomerAlerts from './CustomerAlerts/CustomerAlerts'
import ContractorAlerts from './ContractorAlerts/ContractorAlerts'

export default function ContractorDashboard(props) {
    const [addPopoverVisible, setAddPopoverVisible] = useState(false)
    const [linkAccountsPopoverVisible, setLinkAccountsPopoverVisible] =
        useState(false)
    const { getAccessTokenSilently } = useAuth0()
    const [
        createCustomerChargeInvoicePopoverVisible,
        setCreateCustomerChargeInvoicePopoverVisible,
    ] = useState(false)
    const [createInvoicePopover, setCreateInvoicePopover] = useState(false)

    const [customerSearch, setCustomerSearch] = useState('')
    const [searchActiveCustomers, setSearchActiveCustomers] = useState(true)
    const [searchPendingJobs, setSearchPendingJobs] = useState(false)
    const [searchPastDueInvoices, setSearchPastDueInvoices] = useState(false)
    const [searchMonthlyCustomers, setSearchMonthlyCustomers] = useState(false)
    const [searchPendingCharges, setSearchPendingCharges] = useState(false)
    const [customerSearchType, setCustomerSearchType] = useState('firstName')
    const [loadingStatus, setLoadingStatus] = useState('loading')
    const [open, setOpen] = useState(false)
    const [toggle, setToggle] = useState('View Customers')
    const [contractorData, setContractorData] = useState({})
    const [customerDetail, setCustomerDetail] = useState({})
    const [routeDetail, setRouteDetail] = useState({})
    const [customers, setCustomers] = useState({})

    const CONTRACTOR_VIEWS = {
        ACCOUNT: 'Account',
        VIEW_CUSTOMERS: 'View Customers',
        MESSAGES: 'Messages',
        ADD_USER: 'Add User',
        CREATE_INVOICE: 'Create & Invoice',
        LINK_ACCOUNTS: 'Link Accounts',
        VIEW_ROUTES: 'View Routes',
        CUSTOMER_DETAIL_VIEW: 'Customer Detail View',
        CUSTOMER_DETAIL_INVOICES: 'Customer Detail Invoices',
    }

    const CUSTOMER_VIEWS = {
        CUSTOMER_DETAIL_VIEW: 'Customer Detail View',
        CUSTOMER_DETAIL_INVOICES: 'Customer Detail Invoices',
        CUSTOMER_DETAIL_CHARGES: 'Customer Detail Charges',
        CUSTOMER_DETAIL_PAYMENTS: 'Customer Detail Payments',
        CUSTOMER_DETAIL_JOBS: 'Customer Detail Jobs',
        CUSTOMER_DETAIL_INTERACTIONS: 'Customer Detail Interactions',
    }

    const ROUTE_VIEWS = {
        ROUTE_DETAIL: 'Route Detail',
    }

    const ROUTE_CREATE_VIEWS = {
        CREATE_A_NEW_ROUTE: 'Create A New Route',
    }

    const ROUTE_CREATE_OPTIMIZED_VIEWS = {
        OPTIMIZED_ROUTE: 'Optimized Route',
    }

    const ROUTE_IN_PROGRESS = {
        ROUTE_IN_PROGRESS: 'Route In Progress',
    }

    const theme = useTheme()

    const setToCustomerDetail = (customer) => {
        setCustomerDetail(customer)
        setToggle('Customer Detail View')
    }

    const setToRouteDetail = (route) => {
        setRouteDetail(route)
        setToggle('Route Detail')
    }

    const handleBackToCustomerList = () => {
        setCustomerDetail({})
        setToggle('View Customers')
    }

    const setToCreateNewRoute = () => {
        setToggle('Create A New Route')
    }

    const setToOptimizedRoute = () => {
        setToggle('Optimized Route')
    }

    const setBackToRoutes = () => {
        getContractorDashboard()
        setToggle('View Routes')
    }

    const enterRouteInProgress = (route) => {
        setRouteDetail(route)
        setToggle('Route In Progress')
    }

    const balance = useMemo(() => {
        let total = 0
        contractorData?.invoices?.forEach((invoice) => {
            if (invoice.invoice_status === 'unpaid') {
                total += parseInt(invoice.total_amount)
            }
        })
        return total
    }, [contractorData.invoices])

    const pendingCharges = useMemo(() => {
        let total = 0
        contractorData?.charges?.forEach((charge) => {
            if (charge?.charge_status === 'pending') {
                total += parseInt(charge?.amount)
            }
        })
        return total
    }, [contractorData.charges])

    const handleToggle = (newToggle) => {
        getContractorDashboard()
        setToggle(newToggle)
    }

    const handleMarkAsRead = (message) => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()
            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_DB}/markMessageAsRead`,
                    {
                        message_id: message.message_id,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    getContractorDashboard()
                })
                .catch((error) => {
                    console.error(error)
                })
        }
        getJWT()
    }

    const getContractorDashboard = useCallback(() => {
        //don't retreive the data if the create invoice popover is being opened
        if (createInvoicePopover) {
            return
        }
        const getJWT = async () => {
            setLoadingStatus('loading')
            const accessToken = await getAccessTokenSilently()

            await axios
                .get(
                    `${process.env.REACT_APP_BACKEND_DB}/getContractorDashboard/${props.contractorID}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    setContractorData(response?.data)
                    setCustomers(response?.data?.users)
                    //if the customer detail is set, update the customer detail
                    if (customerDetail?.user_id) {
                        const updatedCustomer = response?.data?.users.find(
                            (customer) =>
                                customer.user_id === customerDetail.user_id
                        )
                        setCustomerDetail(updatedCustomer)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
            setLoadingStatus('loaded')
        }
        getJWT()
    }, [
        getAccessTokenSilently,
        props.contractorID,
        createInvoicePopover,
        customerDetail?.user_id,
    ])

    const prettierPhoneNumber = (phoneNumber) => {
        if (phoneNumber) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
                3,
                6
            )}-${phoneNumber.slice(6, 10)}`
        }
        return ''
    }

    const renderList = () => {
        const customerViews = Object.values(CUSTOMER_VIEWS).includes(toggle)
        const routeViews = Object.values(ROUTE_VIEWS).includes(toggle)
        const routeCreateViews =
            Object.values(ROUTE_CREATE_VIEWS).includes(toggle)
        const routeCreateOptimizedViews = Object.values(
            ROUTE_CREATE_OPTIMIZED_VIEWS
        ).includes(toggle)
        const routeInProgress =
            Object.values(ROUTE_IN_PROGRESS).includes(toggle)

        const isMobile = window.innerWidth < 600

        const handleListItemClick = (toggle) => {
            handleToggle(toggle)
        }

        const customerListItem = [
            {
                toggle: CUSTOMER_VIEWS.CUSTOMER_DETAIL_INVOICES,
                icon: <NoteAdd />,
                text: 'Invoices',
            },
            {
                toggle: CUSTOMER_VIEWS.CUSTOMER_DETAIL_CHARGES,
                icon: <CreditCard />,
                text: 'Charges',
            },
            {
                toggle: CUSTOMER_VIEWS.CUSTOMER_DETAIL_PAYMENTS,
                icon: <Paid />,
                text: 'Payments',
            },
            {
                toggle: CUSTOMER_VIEWS.CUSTOMER_DETAIL_JOBS,
                icon: <Work />,
                text: 'Jobs',
            },
            {
                toggle: CUSTOMER_VIEWS.CUSTOMER_DETAIL_INTERACTIONS,
                icon: <ConnectWithoutContact />,
                text: 'Interactions',
            },
        ]

        const contractorListItem = [
            {
                toggle: CONTRACTOR_VIEWS.ACCOUNT,
                icon: <AccountBox />,
                text: 'Account',
            },
            {
                toggle: CONTRACTOR_VIEWS.VIEW_CUSTOMERS,
                icon: <PeopleOutline />,
                text: 'View Customers',
            },
            {
                toggle: CONTRACTOR_VIEWS.MESSAGES,
                icon: <Message />,
                text: 'Messages',
            },
            {
                toggle: CONTRACTOR_VIEWS.ADD_USER,
                icon: <PersonAddAlt1 />,
                text: 'Add User',
            },
            {
                toggle: CONTRACTOR_VIEWS.CREATE_INVOICE,
                icon: <NoteAdd />,
                text: 'Create & Invoice',
            },
            {
                toggle: CONTRACTOR_VIEWS.LINK_ACCOUNTS,
                icon: <ConnectWithoutContact />,
                text: 'Link Accounts',
            },
            {
                toggle: CONTRACTOR_VIEWS.VIEW_ROUTES,
                icon: <RouteIcon />,
                text: 'View Routes',
            },
        ]

        const routeListItem = [
            {
                //back to view routes
                toggle: 'View Routes',
                icon: <ArrowBack />,
                text: 'Back to Routes',
            },
            {
                toggle: 'Route Detail',
                icon: <RouteIcon />,
                text: 'Route Detail',
            },
        ]

        const routeCreateViewsItem = [
            {
                //back to view routes
                toggle: 'View Routes',
                icon: <ArrowBack />,
                text: 'Back to Routes',
            },
            {
                toggle: 'Create A New Route',
                icon: <RouteIcon />,
                text: 'Create A New Route',
            },
        ]

        const routeCreateOptimizedViewsItem = [
            {
                //back to view routes
                toggle: 'Create A New Route',
                icon: <ArrowBack />,
                text: 'Back to Create Route',
            },
            {
                toggle: 'Optimized Route',
                icon: <RouteIcon />,
                text: 'Optimized Route',
            },
        ]

        const routeInProgressItem = [
            {
                //back to view routes
                toggle: 'View Routes',
                icon: <ArrowBack />,
                text: 'Back to Routes',
            },
            {
                toggle: 'Route In Progress',
                icon: <RouteIcon />,
                text: 'Route In Progress',
            },
        ]

        const listItems = customerViews ? (
            <>
                {' '}
                <ListItemButton onClick={() => handleBackToCustomerList()}>
                    <ArrowBack />
                    <ListItemText
                        sx={{
                            paddingLeft: '4px',
                        }}
                        primary="Back"
                    />
                </ListItemButton>
                {customerListItem.map((item) => (
                    <ListItemButton
                        key={item.toggle}
                        sx={{
                            backgroundColor:
                                toggle === item.toggle
                                    ? theme.palette.primary.backup
                                    : null,
                            color:
                                toggle === item.toggle
                                    ? theme.palette.primary.textWhite
                                    : theme.palette.primary.textBlack,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.textBlack,
                            },
                        }}
                        onClick={() => handleListItemClick(item.toggle)}
                    >
                        {item.icon}
                        <ListItemText
                            sx={{
                                paddingLeft: '4px',
                            }}
                            primary={item.text}
                        />
                    </ListItemButton>
                ))}
            </>
        ) : routeViews ? (
            routeListItem.map((item) => (
                <ListItemButton
                    key={item.toggle}
                    sx={{
                        backgroundColor:
                            toggle === item.toggle
                                ? theme.palette.primary.backup
                                : null,
                        color:
                            toggle === item.toggle
                                ? theme.palette.primary.textWhite
                                : theme.palette.primary.textBlack,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.textBlack,
                        },
                    }}
                    onClick={() => handleListItemClick(item.toggle)}
                >
                    {item.icon}
                    <ListItemText
                        sx={{
                            paddingLeft: '4px',
                        }}
                        primary={item.text}
                    />
                </ListItemButton>
            ))
        ) : routeCreateViews ? (
            routeCreateViewsItem.map((item) => (
                <ListItemButton
                    key={item.toggle}
                    sx={{
                        backgroundColor:
                            toggle === item.toggle
                                ? theme.palette.primary.backup
                                : null,
                        color:
                            toggle === item.toggle
                                ? theme.palette.primary.textWhite
                                : theme.palette.primary.textBlack,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.textBlack,
                        },
                    }}
                    onClick={() => handleListItemClick(item.toggle)}
                >
                    {item.icon}
                    <ListItemText
                        sx={{
                            paddingLeft: '4px',
                        }}
                        primary={item.text}
                    />
                </ListItemButton>
            ))
        ) : routeCreateOptimizedViews ? (
            routeCreateOptimizedViewsItem.map((item) => (
                <ListItemButton
                    key={item.toggle}
                    sx={{
                        backgroundColor:
                            toggle === item.toggle
                                ? theme.palette.primary.backup
                                : null,
                        color:
                            toggle === item.toggle
                                ? theme.palette.primary.textWhite
                                : theme.palette.primary.textBlack,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.textBlack,
                        },
                    }}
                    onClick={() => handleListItemClick(item.toggle)}
                >
                    {item.icon}
                    <ListItemText
                        sx={{
                            paddingLeft: '4px',
                        }}
                        primary={item.text}
                    />
                </ListItemButton>
            ))
        ) : routeInProgress ? (
            routeInProgressItem.map((item) => (
                <ListItemButton
                    key={item.toggle}
                    sx={{
                        backgroundColor:
                            toggle === item.toggle
                                ? theme.palette.primary.backup
                                : null,
                        color:
                            toggle === item.toggle
                                ? theme.palette.primary.textWhite
                                : theme.palette.primary.textBlack,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.textBlack,
                        },
                    }}
                    onClick={() => handleListItemClick(item.toggle)}
                >
                    {item.icon}
                    <ListItemText
                        sx={{
                            paddingLeft: '4px',
                        }}
                        primary={item.text}
                    />
                </ListItemButton>
            ))
        ) : (
            contractorListItem.map((item) => (
                <ListItemButton
                    key={item.toggle}
                    sx={{
                        backgroundColor:
                            toggle === item.toggle
                                ? theme.palette.primary.backup
                                : null,
                        color:
                            toggle === item.toggle
                                ? theme.palette.primary.textWhite
                                : theme.palette.primary.textBlack,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.textBlack,
                        },
                    }}
                    onClick={() => handleListItemClick(item.toggle)}
                >
                    {item.icon}
                    <ListItemText
                        sx={{
                            paddingLeft: '4px',
                        }}
                        primary={item.text}
                    />
                    {
                        //if the user has unread messages, display a badge
                        item.text === 'Messages' &&
                        contractorData?.messages?.filter(
                            (message) => message.read_status === false
                        ).length > 0 ? (
                            <Badge
                                badgeContent={
                                    contractorData?.messages?.filter(
                                        (message) =>
                                            message.read_status === false
                                    ).length
                                }
                                color="error"
                            />
                        ) : null
                    }
                </ListItemButton>
            ))
        )

        return (
            <List sx={{ padding: 0 }}>
                {isMobile && (
                    <>
                        {open && (
                            <>
                                {customerViews ? (
                                    <ListItemButton
                                        onClick={() =>
                                            handleBackToCustomerList()
                                        }
                                    >
                                        <ArrowBack />
                                        <ListItemText
                                            sx={{
                                                paddingLeft: '4px',
                                            }}
                                            primary="Back"
                                        />
                                    </ListItemButton>
                                ) : null}

                                {customerViews && (
                                    <ListItem
                                        sx={{
                                            wordWrap: 'break-word',
                                            overflowWrap: 'break-word',
                                            backgroundColor:
                                                theme.palette.primary
                                                    .supportingWhite,
                                        }}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                        >
                                            <Typography fontWeight={'bold'}>
                                                {customerDetail.first_name +
                                                    ' ' +
                                                    customerDetail.last_name}
                                            </Typography>

                                            <Typography>
                                                {prettierPhoneNumber(
                                                    customerDetail.phone_number
                                                )}
                                            </Typography>

                                            <Typography
                                                sx={{
                                                    wordBreak: 'break-all',
                                                    overflowWrap: 'anywhere',
                                                }}
                                            >
                                                {customerDetail.email}
                                            </Typography>

                                            <Typography>
                                                {customerDetail.address}
                                            </Typography>
                                            <Typography
                                                paddingTop={1}
                                                paddingBottom={1}
                                                color={
                                                    theme.palette.primary
                                                        .supportingBlack
                                                }
                                            >
                                                Property Notes:{' '}
                                                {customerDetail.property_notes}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                )}
                                {listItems}
                                <ListItemButton onClick={() => setOpen(!open)}>
                                    <ListItemText primary="View Less" />{' '}
                                    <ArrowDropUp />
                                </ListItemButton>
                            </>
                        )}
                        {!open &&
                            (customerViews ? (
                                <>
                                    <ListItemButton
                                        onClick={() =>
                                            handleBackToCustomerList()
                                        }
                                    >
                                        <ArrowBack />
                                        <ListItemText
                                            sx={{
                                                paddingLeft: '4px',
                                            }}
                                            primary="Back"
                                        />
                                    </ListItemButton>
                                    <ListItemButton
                                        onClick={() => setOpen(!open)}
                                    >
                                        <ListItemText primary="View More" />{' '}
                                        <ArrowDownward />
                                    </ListItemButton>
                                </>
                            ) : (
                                <>
                                    <ListItemButton
                                        onClick={() => setOpen(!open)}
                                    >
                                        <ListItemText primary="View More" />{' '}
                                        <ArrowDownward />
                                    </ListItemButton>
                                </>
                            ))}
                    </>
                )}
                {!isMobile && (
                    <>
                        {customerViews ? (
                            <>
                                <ListItem
                                    sx={{
                                        wordWrap: 'break-word',
                                        overflowWrap: 'break-word',
                                        backgroundColor:
                                            theme.palette.primary
                                                .supportingWhite,
                                    }}
                                >
                                    <Box display="flex" flexDirection="column">
                                        <Typography fontWeight={'bold'}>
                                            {customerDetail.first_name +
                                                ' ' +
                                                customerDetail.last_name}
                                        </Typography>
                                        <Typography>
                                            {prettierPhoneNumber(
                                                customerDetail.phone_number
                                            )}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                wordBreak: 'break-all',
                                                overflowWrap: 'anywhere',
                                            }}
                                        >
                                            {customerDetail.email}
                                        </Typography>
                                        <Typography>
                                            {customerDetail.address}
                                        </Typography>
                                        <Typography
                                            paddingTop={1}
                                            paddingBottom={1}
                                            color={
                                                theme.palette.primary
                                                    .supportingBlack
                                            }
                                        >
                                            Property Notes:{' '}
                                            {customerDetail.property_notes}
                                        </Typography>
                                    </Box>
                                </ListItem>
                                {listItems}
                            </>
                        ) : (
                            <>
                                <ListItem
                                    sx={{
                                        backgroundColor:
                                            theme.palette.primary
                                                .supportingWhite,
                                    }}
                                >
                                    {contractorData?.user?.first_name &&
                                    contractorData?.user?.last_name ? (
                                        <Typography fontWeight={'bold'}>
                                            {contractorData?.user?.first_name}{' '}
                                            {contractorData?.user?.last_name}
                                        </Typography>
                                    ) : contractorData?.user?.first_name ? (
                                        <Typography fontWeight={'bold'}>
                                            {contractorData?.user?.first_name}
                                        </Typography>
                                    ) : (
                                        <Typography fontWeight={'bold'}>
                                            Contractor Dashboard
                                        </Typography>
                                    )}
                                </ListItem>
                                <ListItem
                                    sx={{
                                        backgroundColor:
                                            theme.palette.primary
                                                .supportingWhite,
                                    }}
                                >
                                    <Typography>
                                        Open Invoices:{' '}
                                        {loadingStatus === 'loading' ? (
                                            <CircularProgress size={'1.2rem'} />
                                        ) : (
                                            <CurrencyFormat
                                                value={balance}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                                decimalScale={2}
                                            />
                                        )}
                                    </Typography>
                                </ListItem>
                                <ListItem
                                    sx={{
                                        backgroundColor:
                                            theme.palette.primary
                                                .supportingWhite,
                                    }}
                                >
                                    <Typography>
                                        Pending Charges:{' '}
                                        {loadingStatus === 'loading' ? (
                                            <CircularProgress size={'1.2rem'} />
                                        ) : (
                                            <CurrencyFormat
                                                value={pendingCharges}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                                decimalScale={2}
                                            />
                                        )}
                                    </Typography>
                                </ListItem>
                                {listItems}
                            </>
                        )}
                    </>
                )}
            </List>
        )
    }

    useEffect(() => {
        getContractorDashboard()
    }, [
        props.contractorID,
        addPopoverVisible,
        linkAccountsPopoverVisible,
        createCustomerChargeInvoicePopoverVisible,
        getAccessTokenSilently,
        getContractorDashboard,
    ])

    return (
        <Box height={'100%'} width={'100%'}>
            <Helmet>
                <title>Contractor Dashboard</title>
                <meta
                    name="description"
                    content="A dashboard for contractors to manage their customers and jobs."
                />
                <meta name="robots" content="noindex, nofollow" />
                <meta
                    name="og:title"
                    property="og:title"
                    content="Contractor Dashboard"
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://allroundcontractingllc.com/dashboard"
                />
                <meta
                    property="og:description"
                    content="A dashboard for contractors to manage their customers and jobs."
                />
                <meta
                    property="og:image"
                    content="https://allroundcontractingllc.com/static/media/ARC.fce879f1b4533f68cf02.png"
                />
                <link
                    rel="canonical"
                    href="https://allroundcontractingllc.com/dashboard"
                />
            </Helmet>
            <Grid container>
                <Grid item xs={12} sm={2}>
                    <Paper
                        sx={{
                            height: '100%',
                            borderRadius: 0,
                        }}
                    >
                        {renderList()}
                    </Paper>
                </Grid>
                <Grid item xs={12} md={10}>
                    {toggle === 'Account' && (
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <CustomerInfoEditable
                                    customerID={props.contractorID}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ContractorAlerts
                                    contractorData={contractorData}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {toggle === 'View Customers' && (
                        <Box display="flex" flexDirection="column" p={2}>
                            <Box textAlign="center" p={2}>
                                <Typography
                                    variant="h4"
                                    color={theme.palette.primary.textWhite}
                                >
                                    Customer List
                                </Typography>
                            </Box>
                            {loadingStatus === 'loading' ? (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    p={2}
                                >
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Box>
                                    <Box pb={2}>
                                        <CustomerSearch
                                            setCustomerSearch={
                                                setCustomerSearch
                                            }
                                            customerSearch={customerSearch}
                                            setCustomerSearchType={
                                                setCustomerSearchType
                                            }
                                            customerSearchType={
                                                customerSearchType
                                            }
                                            searchPastDueInvoices={
                                                searchPastDueInvoices
                                            }
                                            setSearchPastDueInvoices={
                                                setSearchPastDueInvoices
                                            }
                                            searchActiveCustomers={
                                                searchActiveCustomers
                                            }
                                            setSearchActiveCustomers={
                                                setSearchActiveCustomers
                                            }
                                            searchPendingJobs={
                                                searchPendingJobs
                                            }
                                            setSearchPendingJobs={
                                                setSearchPendingJobs
                                            }
                                            searchMonthlyCustomers={
                                                searchMonthlyCustomers
                                            }
                                            setSearchMonthlyCustomers={
                                                setSearchMonthlyCustomers
                                            }
                                            searchPendingCharges={
                                                searchPendingCharges
                                            }
                                            setSearchPendingCharges={
                                                setSearchPendingCharges
                                            }
                                        />
                                    </Box>

                                    <CustomerTable
                                        customers={customers}
                                        customerSearch={customerSearch}
                                        customerSearchType={customerSearchType}
                                        setToCustomerDetail={
                                            setToCustomerDetail
                                        }
                                        searchPastDueInvoices={
                                            searchPastDueInvoices
                                        }
                                        searchActiveCustomers={
                                            searchActiveCustomers
                                        }
                                        searchPendingJobs={searchPendingJobs}
                                        searchMonthlyCustomers={
                                            searchMonthlyCustomers
                                        }
                                        searchPendingCharges={
                                            searchPendingCharges
                                        }
                                    />
                                </Box>
                            )}
                        </Box>
                    )}
                    {toggle === 'Messages' && (
                        <Messages
                            messages={contractorData.messages}
                            isLoading={loadingStatus === 'loading'}
                            handleMarkAsRead={handleMarkAsRead}
                        />
                    )}
                    {toggle === 'Add User' && (
                        <AddUserPopover
                            setAddPopoverVisible={setAddPopoverVisible}
                            addPopoverVisible={addPopoverVisible}
                            contractorID={props.contractorID}
                            setPopoverVisible={handleToggle}
                        />
                    )}

                    {toggle === 'Create & Invoice' && (
                        <AddUserChargeInvoicePopover
                            setCreateCustomerChargeInvoicePopoverVisible={
                                setCreateCustomerChargeInvoicePopoverVisible
                            }
                            createCustomerChargeInvoicePopoverVisible={
                                createCustomerChargeInvoicePopoverVisible
                            }
                            contractorID={props.contractorID}
                            setPopoverVisible={handleToggle}
                        />
                    )}
                    {toggle === 'Link Accounts' && (
                        <LinkAccountsPopover
                            setLinkAccountsPopoverVisible={
                                setLinkAccountsPopoverVisible
                            }
                            linkAccountsPopoverVisible={
                                linkAccountsPopoverVisible
                            }
                            contractorID={props.contractorID}
                            setPopoverVisible={handleToggle}
                        />
                    )}
                    {toggle === 'View Routes' && (
                        <Routes
                            contractorID={props.contractorID}
                            routes={contractorData.routes}
                            setToRouteDetail={setToRouteDetail}
                            setToCreateNewRoute={setToCreateNewRoute}
                        />
                    )}
                    {toggle === 'Customer Detail View' && (
                        <Grid container>
                            <Grid item xs={12} md={6} padding={2}>
                                <CustomerInfoContractorView
                                    handleToggle={handleToggle}
                                    customer={customerDetail}
                                    getContractorDashboard={
                                        getContractorDashboard
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomerAlerts customer={customerDetail} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomerPricing customer={customerDetail} />
                            </Grid>
                        </Grid>
                    )}
                    {toggle === CUSTOMER_VIEWS.CUSTOMER_DETAIL_INVOICES && (
                        <Invoices
                            createInvoicePopover={createInvoicePopover}
                            setCreateInvoicePopover={setCreateInvoicePopover}
                            isLoading={loadingStatus === 'loading'}
                            customerDetail={customerDetail}
                            getContractorDashboard={getContractorDashboard}
                        />
                    )}
                    {toggle === CUSTOMER_VIEWS.CUSTOMER_DETAIL_CHARGES && (
                        <Charges
                            customerID={customerDetail.user_id}
                            getContractorDashboard={getContractorDashboard}
                        />
                    )}
                    {toggle === CUSTOMER_VIEWS.CUSTOMER_DETAIL_PAYMENTS && (
                        <Payments
                            customerID={customerDetail.user_id}
                            getContractorDashboard={getContractorDashboard}
                        />
                    )}
                    {toggle === CUSTOMER_VIEWS.CUSTOMER_DETAIL_JOBS && (
                        <Jobs
                            customerID={customerDetail.user_id}
                            getContractorDashboard={getContractorDashboard}
                        />
                    )}
                    {toggle === CUSTOMER_VIEWS.CUSTOMER_DETAIL_INTERACTIONS && (
                        <Interactions
                            customerID={customerDetail.user_id}
                            getContractorDashboard={getContractorDashboard}
                        />
                    )}
                    {toggle === ROUTE_VIEWS.ROUTE_DETAIL && (
                        <SingleRoute
                            route={routeDetail}
                            enterRouteInProgress={enterRouteInProgress}
                            setBackToRoutes={setBackToRoutes}
                        />
                    )}
                    {(toggle === ROUTE_CREATE_VIEWS.CREATE_A_NEW_ROUTE ||
                        toggle ===
                            ROUTE_CREATE_OPTIMIZED_VIEWS.OPTIMIZED_ROUTE) && (
                        <CreateANewRoute
                            contractorID={props.contractorID}
                            setToOptimizedRoute={setToOptimizedRoute}
                            toggle={toggle}
                            setBackToRoutes={setBackToRoutes}
                        />
                    )}
                    {toggle === ROUTE_IN_PROGRESS.ROUTE_IN_PROGRESS && (
                        <RouteInProgress
                            routeID={routeDetail.route_id}
                            setBackToRoutes={setBackToRoutes}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    )
}
