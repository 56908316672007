import React from 'react'
import { Typography, Box, LinearProgress } from '@mui/material'
import moment from 'moment'

const CustomerRouteTracker = ({ routes }) => {
    return (
        <Box>
            <Typography
                variant="h4"
                sx={{
                    color: 'primary.main',
                    fontWeight: 'bold',
                }}
            >
                Route Tracker
            </Typography>
            {!routes ? (
                <Typography>
                    You are not currently scheduled for a route
                </Typography>
            ) : (
                routes?.map((route, index) => {
                    return (
                        <Box
                            key={index}
                            sx={{
                                mt: 2,
                                p: 2,
                                borderRadius: 1,
                                boxShadow: 1,
                                bgcolor: 'secondary.main',
                            }}
                        >
                            <Typography variant="h6">
                                Route {index + 1} -{' '}
                                {moment(route.route_date).format('MM/DD/YYYY')}
                            </Typography>
                            <Typography>
                                {
                                    //if more than 5 stops, show 5+ stops
                                    route.stops_to_customer > 5
                                        ? '5+ stops remaining until your property'
                                        : route.stops_to_customer +
                                          ' stops remaining until your property'
                                }
                            </Typography>
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                flexDirection={'column'}
                                mt={2}
                            >
                                <Box sx={{ width: '100%', mt: 1 }}>
                                    <LinearProgress
                                        variant="determinate"
                                        value={route.percentage_to_complete}
                                    />
                                </Box>
                                <Box
                                    sx={{ width: '100%', mt: 1 }}
                                    display={'flex'}
                                    alignItems={'center'}
                                    flexDirection={'column'}
                                >
                                    <Typography>
                                        {route.percentage_to_complete}% of the
                                        way to your property
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    )
                })
            )}
        </Box>
    )
}

export default CustomerRouteTracker
