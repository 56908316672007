//This will be the customer interactions table. It will display all of the interactions that a customer has had with the contractor.
//It will display the following:
// - Date
// - Interaction Type
// - Interaction Notes
// - Interaction Amount
// - Interaction Status
//
//The table should be a MUI table
// The data will be populated from the database. The customer ID will be passed in as a prop.
//
// */

import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@mui/system'
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    Typography,
} from '@mui/material'
import { Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import AddCustomerInteractionPopover from '../../../modals/AddCustomerInteractionPopover/AddCustomerInteractionPopover'
import moment from 'moment'
import CustomPopover from '../../../modals/CustomPopover/CustomPopover'
import CreateEstimate from '../CreateEstimate/CreateEstimate'
import { useNavigate } from 'react-router-dom'
import Success from '../../FormStatusIcons/Success'
import Failed from '../../FormStatusIcons/Failed'
import CircularProgress from '@mui/material/CircularProgress'
import { Card, CardContent, Grid } from '@mui/material'

export default function Interactions(props) {
    const { getAccessTokenSilently } = useAuth0()
    const theme = useTheme()
    const { customerID } = props
    const [
        AddCustomerInteractionPopoverVisible,
        setAddCustomerInteractionPopoverVisible,
    ] = useState(false)
    const [createEstimatePopoverVisible, setCreateEstimatePopoverVisible] =
        useState(false)

    const [viewEstimateInteraction, setViewEstimateInteraction] =
        useState(false)
    const [viewMetadata, setViewMetadata] = useState(false)
    const [customerInteractionInFocus, setCustomerInteractionInFocus] =
        useState('')
    const [
        viewCustomerInteractionPopoverVisible,
        setViewCustomerInteractionPopoverVisible,
    ] = useState(false)

    const [interactions, setInteractions] = useState([])
    const [interactionStatus, setInteractionStatus] = useState('idle')
    const navigate = useNavigate()

    const filteredInteractions = interactions

    const handleViewInteraction = (interaction) => {
        setCustomerInteractionInFocus(interaction)
        setViewCustomerInteractionPopoverVisible(true)
    }

    const clickViewEstimateInteraction = (interaction) => {
        setCustomerInteractionInFocus(interaction)
        setViewEstimateInteraction(true)
    }

    const handleViewMetadata = (interaction) => {
        setCustomerInteractionInFocus(interaction)
        setViewMetadata(true)
    }

    const handleDeleteInteraction = async (interaction) => {
        setInteractionStatus('Deleting Interaction...')
        const accessToken = await getAccessTokenSilently()

        axios

            .delete(
                `${process.env.REACT_APP_BACKEND_DB}/deleteInteraction/${interaction.interaction_id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then((response) => {
                setInteractionStatus(response?.data?.message)
                setInteractions(response?.data?.interactions)
                setTimeout(() => {
                    setInteractionStatus('idle')
                }, 1000)
            })
    }

    const sendReEngagementEmail = async () => {
        setInteractionStatus('Creating Re-Engagement Email...')
        const accessToken = await getAccessTokenSilently()

        axios
            .post(
                `${process.env.REACT_APP_BACKEND_DB}/sendReEngagementEmail`,
                {
                    user_id: customerID,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then((response) => {
                setInteractionStatus(response?.data?.message)
                setInteractions(response?.data?.interactions)
                setTimeout(() => {
                    setInteractionStatus('idle')
                }, 5000)
            })
    }

    const sendRegistrationEmail = async () => {
        setInteractionStatus('Creating Registration Email...')
        const accessToken = await getAccessTokenSilently()

        axios
            .post(
                `${process.env.REACT_APP_BACKEND_DB}/sendRegistrationEmail`,
                {
                    user_id: customerID,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then((response) => {
                setInteractionStatus(response?.data?.message)
                setInteractions(response?.data?.interactions)
                setTimeout(() => {
                    setInteractionStatus('idle')
                }, 5000)
            })
    }

    useEffect(() => {
        if (
            AddCustomerInteractionPopoverVisible ||
            createEstimatePopoverVisible
        ) {
            return
        }

        const getInteractions = async () => {
            setInteractionStatus('loading')
            const accessToken = await getAccessTokenSilently()

            axios
                .get(
                    `${process.env.REACT_APP_BACKEND_DB}/getInteractions/${customerID}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    setInteractions(response.data)
                    setInteractionStatus('idle')
                })
        }
        //only get the interactions if the popover is not visible
        !AddCustomerInteractionPopoverVisible && getInteractions()
    }, [
        AddCustomerInteractionPopoverVisible,
        customerID,
        getAccessTokenSilently,
        createEstimatePopoverVisible,
    ])
    if (interactionStatus === 'loading') {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                >
                    <Typography
                        variant="h4"
                        color={theme.palette.primary.textWhite}
                    >
                        Interactions
                    </Typography>
                </Box>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box>
            {AddCustomerInteractionPopoverVisible && (
                <AddCustomerInteractionPopover
                    customerID={customerID}
                    setPopoverVisible={setAddCustomerInteractionPopoverVisible}
                    interaction={customerInteractionInFocus}
                />
            )}
            {viewCustomerInteractionPopoverVisible && (
                <CustomPopover
                    setPopoverVisible={setViewCustomerInteractionPopoverVisible}
                    title={'Customer Interaction'}
                >
                    <Box>
                        <Typography>
                            Interaction Date:{' '}
                            {moment
                                .utc(
                                    customerInteractionInFocus.interaction_date
                                )
                                .format('MM/DD/YYYY')}
                        </Typography>
                        <Typography>
                            Interaction Type:{' '}
                            {customerInteractionInFocus.interaction_type}
                        </Typography>
                        <Typography>
                            Interaction Notes:{' '}
                            {customerInteractionInFocus.interaction_notes}
                        </Typography>
                    </Box>
                </CustomPopover>
            )}
            {viewEstimateInteraction ? (
                <CustomPopover setPopoverVisible={setViewEstimateInteraction}>
                    <Box
                        width={'100%'}
                        height={'100%'}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Typography variant={'h4'}>
                            {customerInteractionInFocus.interaction_type}
                        </Typography>
                        <Typography>
                            {customerInteractionInFocus.interaction_notes}
                        </Typography>
                        <Box padding={2}>
                            <Button
                                variant="contained"
                                padding={2}
                                onClick={() => {
                                    navigate(
                                        `/estimate/${customerID}/` +
                                            customerInteractionInFocus?.metadata
                                                ?.estimate_id
                                    )
                                }}
                            >
                                View Estimate
                            </Button>
                        </Box>
                    </Box>
                </CustomPopover>
            ) : null}

            {viewMetadata && (
                <CustomPopover
                    setPopoverVisible={setViewMetadata}
                    title={'Interaction Metadata'}
                >
                    <Box>
                        <Typography>
                            Interaction Date:{' '}
                            {moment
                                .utc(
                                    customerInteractionInFocus.interaction_date
                                )
                                .format('MM/DD/YYYY')}
                        </Typography>
                        <Typography>
                            Interaction Type:{' '}
                            {customerInteractionInFocus.interaction_type}
                        </Typography>
                        <Typography>
                            Interaction Notes:{' '}
                            {customerInteractionInFocus.interaction_notes}
                        </Typography>
                    </Box>
                </CustomPopover>
            )}
            {}
            <Box
                display={'flex'}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Typography
                    variant="h4"
                    color={theme.palette.primary.textWhite}
                >
                    Interactions
                </Typography>
            </Box>
            <Box
                display={'flex'}
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Box padding>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() =>
                            setAddCustomerInteractionPopoverVisible(true)
                        }
                    >
                        Add Interaction
                    </Button>
                </Box>
                <Box padding>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() =>
                            sendReEngagementEmail(
                                customerID,
                                setInteractionStatus
                            )
                        }
                    >
                        Re-Engagement Email
                    </Button>
                </Box>
                <Box padding>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => setCreateEstimatePopoverVisible(true)}
                    >
                        Create Estimate
                    </Button>
                </Box>
                <Box padding>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => sendRegistrationEmail(customerID)}
                    >
                        Sign up Email
                    </Button>
                </Box>
            </Box>
            {
                //if the interaction status is "Email sent", then we want to display a message to the user
                //if the interaction status is "Email not sent, customer has had a Re-Engagement email within the past month" then we want to display a message to the user

                interactionStatus === 'Email sent' ? (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                        p={'1vh'}
                    >
                        <Success title={'Email Sent'}></Success>
                    </Box>
                ) : interactionStatus ===
                  'Email not sent, customer has had a Re-Engagement email within the past 30 days.' ? (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                        p={'1vh'}
                    >
                        <Failed
                            error={
                                'Email not sent, customer has had a Re-Engagement email within the past 30 days.'
                            }
                        ></Failed>
                    </Box>
                ) : interactionStatus ===
                  'Email not sent, user has already finished registration' ? (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                        p={'1vh'}
                    >
                        <Failed
                            error={
                                'Email not sent, user has already finished registration'
                            }
                        ></Failed>
                    </Box>
                ) : interactionStatus === 'Email sent' ? (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                        p={'1vh'}
                    >
                        <Success title={'Email Sent'}></Success>
                    </Box>
                ) : interactionStatus ===
                  'Email not sent, customer has had a Sign Up email within the past 30 days.' ? (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                        p={'1vh'}
                    >
                        <Failed
                            error={
                                'Email not sent, customer has had a Sign Up email within the past 30 days.'
                            }
                        ></Failed>
                    </Box>
                ) : interactionStatus === 'User does not have an email' ? (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                        p={'1vh'}
                    >
                        <Failed
                            error={
                                'User does not have an email. Please add an email to send an email'
                            }
                        ></Failed>
                    </Box>
                ) : null
            }
            {viewMetadata ? (
                <CustomPopover setPopoverVisible={setViewMetadata}>
                    <Box
                        width={'100%'}
                        height={'100%'}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Typography variant={'h4'}>
                            {customerInteractionInFocus.interaction_type}
                        </Typography>
                        <Typography>
                            {customerInteractionInFocus.interaction_notes}
                        </Typography>
                        {customerInteractionInFocus?.metadata?.new_user &&
                        customerInteractionInFocus?.metadata?.old_user ? (
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Field</TableCell>
                                            <TableCell>Old Value</TableCell>
                                            <TableCell>New Value</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(
                                            customerInteractionInFocus?.metadata
                                                ?.new_user
                                        ).every((key) => {
                                            return (
                                                customerInteractionInFocus
                                                    ?.metadata?.new_user[
                                                    key
                                                ] ===
                                                customerInteractionInFocus
                                                    ?.metadata?.old_user[key]
                                            )
                                        }) ? (
                                            <TableRow>
                                                <TableCell colSpan={3}>
                                                    No Changes Made
                                                </TableCell>
                                            </TableRow>
                                        ) : null}
                                        {
                                            //if the old_user and new_user are the same, then we want to display no results found

                                            Object.keys(
                                                customerInteractionInFocus
                                                    ?.metadata?.new_user
                                            ).map((key) => {
                                                //if the old user and new user are different, then we want to display the value
                                                //if the old user and new user are the same, then we don't want to display the value
                                                if (
                                                    customerInteractionInFocus
                                                        ?.metadata?.new_user[
                                                        key
                                                    ] ===
                                                    customerInteractionInFocus
                                                        ?.metadata?.old_user[
                                                        key
                                                    ]
                                                ) {
                                                    return null
                                                }

                                                return (
                                                    <TableRow key={key}>
                                                        <TableCell>
                                                            {
                                                                //replace the underscores with spaces and capitalize the first letter of each word

                                                                key
                                                                    .split('_')
                                                                    .map(
                                                                        (
                                                                            word
                                                                        ) =>
                                                                            word
                                                                                .charAt(
                                                                                    0
                                                                                )
                                                                                .toUpperCase() +
                                                                            word.slice(
                                                                                1
                                                                            )
                                                                    )
                                                                    .join(
                                                                        ' '
                                                                    ) || key
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                customerInteractionInFocus
                                                                    ?.metadata
                                                                    ?.old_user[
                                                                    key
                                                                ]
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                customerInteractionInFocus
                                                                    ?.metadata
                                                                    ?.new_user[
                                                                    key
                                                                ]
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : null}
                    </Box>
                </CustomPopover>
            ) : null}
            {createEstimatePopoverVisible ? (
                <CustomPopover
                    setPopoverVisible={setCreateEstimatePopoverVisible}
                >
                    <CreateEstimate
                        customerID={customerID}
                        setCreateEstimatePopoverVisible={
                            setCreateEstimatePopoverVisible
                        }
                    />
                </CustomPopover>
            ) : null}
            {!filteredInteractions?.length ? (
                <Typography
                    variant="body1"
                    textAlign={'center'}
                    color={theme.palette.primary.textWhite}
                >
                    No Results Found
                </Typography>
            ) : null}
            {filteredInteractions
                ?.sort((a, b) => {
                    return (
                        new Date(b.interaction_date) -
                        new Date(a.interaction_date)
                    )
                })
                .map((interaction, index) => (
                    <Card
                        key={index}
                        style={{
                            margin: '10px',
                            border: '1px solid black',
                            //hover effect
                        }}
                    >
                        <CardContent
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'lightgray',
                                    cursor: 'pointer',
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                position: 'relative',
                            }}
                        >
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    onClick={() =>
                                        interaction.interaction_type ===
                                        'Estimate'
                                            ? clickViewEstimateInteraction(
                                                  interaction
                                              )
                                            : interaction.interaction_type ===
                                              'Update'
                                            ? handleViewMetadata(interaction)
                                            : handleViewInteraction(interaction)
                                    }
                                >
                                    <Typography
                                        variant="h6"
                                        style={{
                                            fontWeight: 'bold',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Interaction Date:{' '}
                                        {moment
                                            .utc(interaction.interaction_date)
                                            .format('MM/DD/YYYY')}
                                    </Typography>
                                    <Typography>
                                        Interaction Type:{' '}
                                        {interaction.interaction_type}
                                    </Typography>
                                    <Typography>
                                        Interaction Notes:{' '}
                                        {interaction.interaction_notes}
                                    </Typography>
                                </Grid>

                                <Grid
                                    container
                                    item
                                    xs={12}
                                    sm={6}
                                    justifyContent={'right'}
                                    alignItems={'flex-end'}
                                >
                                    <Grid item xs={12}>
                                        <Box
                                            display={'flex'}
                                            flexDirection={'row'}
                                            alignItems={'flex-end'}
                                            justifyContent={'right'}
                                            height={'100%'}
                                        >
                                            <Box padding>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() =>
                                                        handleDeleteInteraction(
                                                            interaction
                                                        )
                                                    }
                                                >
                                                    Delete
                                                </Button>
                                            </Box>

                                            <Box padding>
                                                {interaction.interaction_type ===
                                                'Estimate' ? (
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        onClick={() =>
                                                            clickViewEstimateInteraction(
                                                                interaction
                                                            )
                                                        }
                                                    >
                                                        View Estimate
                                                    </Button>
                                                ) : interaction.interaction_type ===
                                                  'Update' ? (
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        onClick={() =>
                                                            handleViewMetadata(
                                                                interaction
                                                            )
                                                        }
                                                    >
                                                        View Metadata
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        onClick={() =>
                                                            handleViewInteraction(
                                                                interaction
                                                            )
                                                        }
                                                    >
                                                        View Interaction
                                                    </Button>
                                                )}
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                ))}
        </Box>
    )
}
