// Import necessary packages
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { COMPONENTS, USER_TYPES } from '../../utils/Constant/Constant'
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard'
import ContractorDashboard from '../ContractorDashboard/ContractorDashboard'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import Loading from '../../utils/Components/SharedOrPublicViews/Loading/Loading'
import { useAuth0 } from '@auth0/auth0-react'
import FinishRegistration from '../../utils/Components/SharedOrPublicViews/FinishRegistration/FinishRegistration'
import Error403 from '../../utils/Components/SharedOrPublicViews/ErrorScenarios/403'
import GeneratePDFInvoice from '../../utils/Components/SharedOrPublicViews/GeneratePDFInvoice/GeneratePDFInvoice'
import Error404 from '../../utils/Components/SharedOrPublicViews/ErrorScenarios/404'
import GeneratePDFEstimate from '../../utils/Components/SharedOrPublicViews/GeneratePDFEstimate/GeneratePDFEstimate'

const DashboardDirector = () => {
    const { user, isLoading, getAccessTokenSilently, loginWithRedirect } =
        useAuth0()
    const { component, user_id, detailed_id } = useParams()

    const [loggedInUser, setLoggedInUser] = useState()
    const [loggedInUserError, setLoggedInUserError] = useState()
    const [requestedPageUser, setRequestedPageUser] = useState()
    const [refreshingData, setRefreshingData] = useState(true)

    //get the users role from the database
    useEffect(() => {
        //if !user then return
        if (!user) {
            return
        }

        const getJWT = async () => {
            setRefreshingData(true)
            const accessToken = await getAccessTokenSilently()

            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_DB}/getSingleUserBySub/${user?.sub}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                setLoggedInUser(response?.data)
            } catch (error) {
                setLoggedInUserError(error)
            }

            try {
                user_id &&
                    axios
                        .get(
                            `${process.env.REACT_APP_BACKEND_DB}/getCustomer/${user_id}`,

                            {
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }
                        )
                        .then((response) => {
                            setRequestedPageUser(response?.data)
                        })
            } catch (error) {
                console.log(error)
            }
            setRefreshingData(false)
        }

        getJWT()
    }, [user, user_id, getAccessTokenSilently])

    switch (isLoading) {
        case false:
            if (!user && COMPONENTS[component]) {
                loginWithRedirect({
                    //redirectURI
                    authorizationParams: {
                        redirect_uri: window.location.origin + '/dashboard',
                    },
                })
                return <Loading />
            } else if (!user && !COMPONENTS[component]) {
                //if the user is not logged in and the component is not a valid component then return 404
                return <Error404 />
            } else if (!user) {
                //if the user is not logged in and the component is a valid component then return 403
                return <Error403 />
            }

            if (refreshingData) {
                return <Loading />
            }
            if (loggedInUserError) {
                return <Error403 />
            }

            switch (loggedInUser?.role) {
                case USER_TYPES.CUSTOMER:
                    switch (requestedPageUser?.customer_sub === user?.sub) {
                        case true:
                            switch (component) {
                                case COMPONENTS.pdf:
                                    return (
                                        <GeneratePDFInvoice
                                            invoiceID={detailed_id}
                                        />
                                    )
                                case COMPONENTS.estimate:
                                    return (
                                        <GeneratePDFEstimate
                                            estimate_id={detailed_id}
                                        />
                                    )
                                default:
                                    return (
                                        <CustomerDashboard
                                            customerID={loggedInUser.user_id}
                                        />
                                    )
                            }
                        default:
                            if (loggedInUser?.user_id) {
                                return (
                                    <CustomerDashboard
                                        customerID={loggedInUser.user_id}
                                    />
                                )
                            } else {
                                return <Error403 />
                            }
                    }

                case USER_TYPES.CONTRACTOR:
                    switch (component) {
                        case COMPONENTS.pdf:
                            return (
                                <GeneratePDFInvoice invoiceID={detailed_id} />
                            )

                        case COMPONENTS.estimate:
                            return (
                                <GeneratePDFEstimate
                                    estimate_id={detailed_id}
                                />
                            )

                        case COMPONENTS.dashboard:
                            /* 
                            commenting this out, currently it causes issues with search params on backedn if the 
                            users searched is not a contractor. 
                            
                            if (user_id) {
                                return (
                                    <ContractorDashboard
                                        contractorID={user_id}
                                    />
                                )
                            } else { */
                            return (
                                <ContractorDashboard
                                    contractorID={loggedInUser.user_id}
                                />
                            )
                        // }
                        default:
                            return (
                                <ContractorDashboard
                                    contractorID={loggedInUser.user_id}
                                />
                            )
                    }

                case USER_TYPES.ADMIN:
                    if (component === COMPONENTS.pdfView) {
                        return <GeneratePDFInvoice />
                    }

                    switch (loggedInUser.customer_sub === user?.sub) {
                        case true:
                            return <AdminDashboard adminID={user_id} />
                        default:
                            return <Loading />
                    }

                default:
                    return <FinishRegistration />
            }

        default:
            return <Loading />
    }
}

export default DashboardDirector
