import React, { useEffect } from 'react'

import { Box } from '@mui/system'
import { PDFViewer } from '@react-pdf/renderer'
import PdfDocument from './InvoiceTemplateFiles/Invoice'
import axios from 'axios'
import { useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Typography } from '@mui/material'
import { Button } from '@mui/material'
import PayInvoice from '../../SharedOrPublicViews/PayInvoice/PayInvoice'
import { Close } from '@mui/icons-material'
import moment from 'moment'
import Loading from '../../SharedOrPublicViews/Loading/Loading'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

export default function GeneratePDFInvoice(props) {
    //Should return a mock invoice in PDF format
    const { getAccessTokenSilently } = useAuth0()
    const location = useLocation()

    const [loading, setLoading] = useState(false)
    const [viewInvoice, setViewInvoice] = useState(false)

    const [payInvoice, setPayInvoice] = useState(false)

    const [formStatus, setFormStatus] = useState('loading')

    const [invoiceUser, setInvoiceUser] = useState({})
    const [invoiceCharges, setInvoiceCharges] = useState([])
    const [invoice, setInvoice] = useState({})

    const theme = useTheme()
    let navigate = useNavigate()

    const invoiceData = {
        title: 'Invoice No: ' + invoice.invoice_id,
        invoice_id: invoice.invoice_id,
        first_name: invoiceUser.first_name,
        last_name: invoiceUser.last_name,
        email: invoiceUser.email,
        phone: invoiceUser.phone,
        address: invoiceUser.address,
        date_generated: invoice.date_generated,
        due_date: invoice.due_date,
        total_amount: invoice.total_amount,
        charges: invoiceCharges,
        invoice_status: invoice.invoice_status,
        user_id: invoice.user_id,
        charge_card_fee: invoice.charge_card_fee,
    }

    const { invoiceID } = props

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
            title: 'PDF Invoice',
        })
    }, [location])

    useEffect(() => {
        setFormStatus('loading')
        const getJWT = async () => {
            setLoading(true)
            const accessToken = await getAccessTokenSilently()

            axios
                .get(
                    `${process.env.REACT_APP_BACKEND_DB}/getInvoiceData/${invoiceID}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((res) => {
                    setInvoice(res.data.invoice)
                    setInvoiceCharges(res.data.charges)
                    setInvoiceUser(res.data.user)
                    setLoading(false)
                    setFormStatus('success')
                })
        }
        getJWT()
    }, [invoiceID, getAccessTokenSilently])

    return (
        <Box
            bgcolor={theme.palette.primary.textWhite}
            width={'100%'}
            height={'100%'}
        >
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'flex-start'}
                alignItems={'center'}
                width={'100%'}
                padding={4}
            >
                <ArrowBackIcon
                    sx={{
                        '&:hover': {
                            color: theme.palette.primary.textWhite,
                            cursor: 'pointer',
                        },
                    }}
                    onClick={() => navigate(-1)}
                />
            </Box>
            <Box
                width={'100%'}
                height={'100%'}
                p={2}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {formStatus !== 'success' ? (
                    <Loading />
                ) : (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography variant="h4" gutterBottom>
                                Invoice Number: {invoice?.invoice_id}
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Date Generated:{' '}
                                {moment
                                    .utc(invoice?.date_generated)

                                    .format('MM/DD/YYYY')}
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Due Date:{' '}
                                {moment
                                    .utc(invoice?.due_date)

                                    .format('MM/DD/YYYY')}
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Invoice Amount:{' '}
                                {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }).format(invoice?.total_amount)}
                            </Typography>
                            {invoice?.invoice_status === 'paid' ? (
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Typography variant="h6" gutterBottom>
                                        Balance Due:{' '}
                                        {new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        }).format(0)}
                                    </Typography>
                                    {payInvoice ? (
                                        <Box>
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                            >
                                                Card Fee:{' '}
                                                {invoice?.charge_card_fee
                                                    ? new Intl.NumberFormat(
                                                          'en-US',
                                                          {
                                                              style: 'currency',
                                                              currency: 'USD',
                                                          }
                                                      ).format(
                                                          invoice?.total_amount *
                                                              0.07
                                                      )
                                                    : new Intl.NumberFormat(
                                                          'en-US',
                                                          {
                                                              style: 'currency',
                                                              currency: 'USD',
                                                          }
                                                      ).format(0.0)}
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                            >
                                                Total:{' '}
                                                {new Intl.NumberFormat(
                                                    'en-US',
                                                    {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                    }
                                                ).format(
                                                    invoice?.total_amount * 1.07
                                                )}
                                            </Typography>
                                        </Box>
                                    ) : null}
                                </Box>
                            ) : invoice?.invoice_status === 'cancelled' ? (
                                <Typography variant="h6" gutterBottom>
                                    Status: CANCELLED
                                </Typography>
                            ) : (
                                <Typography variant="h6" gutterBottom>
                                    Balance Due:{' '}
                                    {new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }).format(invoice?.total_amount)}
                                </Typography>
                            )}
                        </Box>
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            width={'100%'}
                        >
                            {invoice?.invoice_status === 'unpaid' ||
                            invoice?.invoice_status === null ? (
                                <Box padding={2}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor:
                                                theme.palette.primary.backup,
                                            color: theme.palette.primary
                                                .textWhite,
                                        }}
                                        onClick={() => {
                                            setPayInvoice(!payInvoice)
                                            ReactGA.event({
                                                category: 'Invoice',
                                                action: 'Pay Invoice',
                                                label: 'Pay Invoice Clicked',
                                            })
                                        }}
                                    >
                                        {payInvoice
                                            ? 'Hide Pay Invoice'
                                            : 'Pay Invoice'}
                                    </Button>
                                </Box>
                            ) : null}

                            <Box padding={2}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor:
                                            theme.palette.primary.backup,
                                        color: theme.palette.primary.textWhite,
                                    }}
                                    padding={2}
                                    onClick={() => {
                                        ReactGA.event({
                                            category: 'Invoice',
                                            action: viewInvoice
                                                ? 'Hide Invoice'
                                                : 'View Invoice',
                                            label: viewInvoice
                                                ? 'Hide Invoice Clicked'
                                                : 'View Invoice Clicked',
                                        })
                                        setViewInvoice(!viewInvoice)
                                    }}
                                >
                                    {viewInvoice
                                        ? 'Hide Invoice'
                                        : 'View Invoice'}
                                </Button>
                            </Box>
                        </Box>

                        {payInvoice ? (
                            <Box
                                position={'fixed'}
                                top={'0'}
                                left={'0'}
                                width={'100%'}
                                height={'100%'}
                                backgroundColor={'rgba(0, 0, 0, 0.5)'}
                                sx={{
                                    backdropFilter: 'blur(5px)',
                                }}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                zIndex={'2'}
                            >
                                <Box
                                    width={'100%'}
                                    height={'100%'}
                                    position={'absolute'}
                                    top={'0'}
                                    left={'0'}
                                    onClick={() => setPayInvoice(!payInvoice)}
                                ></Box>
                                <Box
                                    zIndex={'1'}
                                    position={'relative'}
                                    width={'auto'}
                                    height={'auto'}
                                    backgroundColor={'white'}
                                    borderRadius={'10px'}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                    padding={'5vh'}
                                    maxHeight={'90%'}
                                    //scroll
                                    overflow={'scroll'}
                                >
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                        alignItems={'flex-start'}
                                        width={'100%'}
                                        marginBottom={'10px'}
                                    >
                                        <Close
                                            position={'static'}
                                            color={'black'}
                                            fontWeight={'bold'}
                                            cursor={'pointer'}
                                            onClick={() =>
                                                setPayInvoice(!payInvoice)
                                            }
                                        />
                                    </Box>
                                    <Box padding={2}>
                                        <Typography variant={'h4'}>
                                            Pay Invoice
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            //make background color just slightly gray
                                            bgcolor={'#f2f2f2'}
                                            padding={2}
                                            borderRadius={5}
                                        >
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                            >
                                                Balance Due:{' '}
                                                {new Intl.NumberFormat(
                                                    'en-US',
                                                    {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                    }
                                                ).format(invoice?.total_amount)}
                                            </Typography>
                                            {payInvoice ? (
                                                <Box>
                                                    <Typography
                                                        variant="h6"
                                                        gutterBottom
                                                    >
                                                        Card Fee:{' '}
                                                        {invoice?.charge_card_fee
                                                            ? new Intl.NumberFormat(
                                                                  'en-US',
                                                                  {
                                                                      style: 'currency',
                                                                      currency:
                                                                          'USD',
                                                                  }
                                                              ).format(
                                                                  invoice?.total_amount *
                                                                      0.07
                                                              )
                                                            : new Intl.NumberFormat(
                                                                  'en-US',
                                                                  {
                                                                      style: 'currency',
                                                                      currency:
                                                                          'USD',
                                                                  }
                                                              ).format(0.0)}
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        gutterBottom
                                                    >
                                                        Total:{' '}
                                                        {new Intl.NumberFormat(
                                                            'en-US',
                                                            {
                                                                style: 'currency',
                                                                currency: 'USD',
                                                            }
                                                        ).format(
                                                            invoice?.charge_card_fee
                                                                ? invoice?.total_amount *
                                                                      1.07
                                                                : invoice?.total_amount
                                                        )}
                                                    </Typography>
                                                </Box>
                                            ) : null}
                                        </Box>
                                    </Box>
                                    <PayInvoice
                                        invoice={invoiceData}
                                    ></PayInvoice>
                                </Box>
                            </Box>
                        ) : null}

                        <PDFDownloadLink
                            document={<PdfDocument invoicedata={invoiceData} />}
                            fileName={
                                invoiceUser?.address +
                                '_invoice_id_' +
                                invoice?.invoice_id +
                                '.pdf'
                            }
                            onClick={() => {
                                ReactGA.event({
                                    category: 'Invoice',
                                    action: 'Download Invoice',
                                    label: 'Download Invoice Clicked',
                                })
                            }}
                        >
                            {loading ? (
                                <Typography variant="h6">
                                    Loading document...
                                </Typography>
                            ) : (
                                <Typography variant="h6">
                                    Download Invoice
                                </Typography>
                            )}
                        </PDFDownloadLink>
                    </Box>
                )}
            </Box>

            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                width={'100%'}
                backgroundColor={theme.palette.primary.background}
                padding={2}
            >
                {viewInvoice ? (
                    <PDFViewer
                        //if the screen size is less than 600 px, make the width 400 px, if it's more than 600 px make it 800 px
                        width={window.innerWidth < 600 ? 400 : 800}
                        height={window.innerHeight}
                        showToolbar={true}
                    >
                        <PdfDocument invoicedata={invoiceData} />
                    </PDFViewer>
                ) : null}
            </Box>
        </Box>
    )
}
