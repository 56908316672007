//This will be the in progress route page. When a route is in progress, this page will display the next house
//on the route, and allow the contractor to mark the job as complete in the route, and add notes to the job.
//The page will display the following:
//1. The next house on the route
//2. A button to mark the job as complete
//3. A button to add notes to the job
//4. A button to view the map of the route
//5. A button to save the route
//6. A button to cancel the route
//7. A button to go to the previous house on the route
//8. A button to go to the next house on the route
//9. A button to go to the previous route

import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@mui/system'
import { TableContainer, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'

import LinearProgress from '@mui/material/LinearProgress'
import Success from '../../FormStatusIcons/Success'
import Failed from '../../FormStatusIcons/Failed'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

export default function RouteInProgress(props) {
    const { getAccessTokenSilently } = useAuth0()
    const theme = useTheme()
    const [modalStatus, setModalStatus] = useState('idle')
    const location = useLocation()

    const { routeID, setBackToRoutes } = props
    const [route, setRoute] = useState()
    const [progress, setProgress] = useState(0)

    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography
                        variant="body2"
                        color={theme.palette.primary.textWhite}
                    >{`(${Math.round(
                        (route.route_customers.length *
                            Math.round(props.value)) /
                            100
                    )}/${route.route_customers.length}) Completed`}</Typography>
                </Box>
            </Box>
        )
    }

    //A function to calculate the progress of the route. The progress is calculated by dividing the number of finished stops and skipped stops by the total number of stops, and multiplying by 100
    //There may be 0, many or undefined amounts of finished stops and skipped stops, so we need to check for all of those cases

    const completeRoute = async () => {
        setModalStatus('submitting')
        //update the route status to complete

        const accessToken = await getAccessTokenSilently()
        axios
            .post(
                `${process.env.REACT_APP_BACKEND_DB}/updateRouteToCompleted`,
                {
                    route_id: routeID,
                },

                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then((response) => {
                setModalStatus('completed')
                setTimeout(() => {
                    setModalStatus('idle')
                    //navigate to the routes page
                    setBackToRoutes()
                }, 1000)
            })
    }

    //An async method to mark a stop as complete

    const completeStop = async () => {
        setModalStatus('submitting')
        const accessToken = await getAccessTokenSilently()
        //Make an axios request to the backend to mark the stop as complete

        axios
            .post(
                `${process.env.REACT_APP_BACKEND_DB}/completeStop`,
                {
                    route_id: routeID,
                    stop_condition: 'Complete',
                    note: 'Stop Completed Successfully',
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then((response) => {
                setModalStatus('success')
                setTimeout(() => {
                    setRoute(response?.data)
                    setModalStatus('idle')
                }, 1000)
            })
    }
    const skipStop = async () => {
        setModalStatus('skipping')
        const accessToken = await getAccessTokenSilently()
        //Make an axios request to the backend to mark the stop as complete

        axios
            .post(
                `${process.env.REACT_APP_BACKEND_DB}/skipStop`,
                {
                    route_id: routeID,
                    stop_condition: 'Skipped',
                    note: 'Stop Skipped',
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then((response) => {
                setModalStatus('skipped')
                setTimeout(() => {
                    axios
                        .get(
                            `${process.env.REACT_APP_BACKEND_DB}/getRouteInProgress/${routeID}`,

                            {
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }
                        )
                        .then((response) => {
                            setModalStatus('idle')
                            setRoute(response?.data)
                        })
                }, 1000)
            })
    }

    //TODO: Get the route from the database

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
            title: 'Route In Progress',
        })
    }, [location])

    useEffect(() => {
        if (route) {
            if (route?.finished_stops && route?.skipped_stops) {
                setProgress(
                    ((route?.finished_stops?.length +
                        route?.skipped_stops?.length) /
                        route?.route_customers?.length) *
                        100
                )
            } else if (route?.finished_stops) {
                setProgress(
                    (route?.finished_stops?.length /
                        route?.route_customers?.length) *
                        100
                )
            } else if (route?.skipped_stops) {
                setProgress(
                    (route?.skipped_stops?.length /
                        route?.route_customers?.length) *
                        100
                )
            } else {
                setProgress(0)
            }
        }
    }, [route])

    useEffect(() => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            routeID &&
                axios
                    .get(
                        `${process.env.REACT_APP_BACKEND_DB}/getRouteInProgress/${routeID}`,

                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        setRoute(response?.data)
                    })
        }

        getJWT()
    }, [getAccessTokenSilently, routeID])

    return (
        <Box minHeight={'75vh'}>
            <Box padding={'2vh'}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    color={theme.palette.primary.textWhite}
                >
                    <Box padding={'2vh'} width={'100%'}>
                        <Grid container spacing={2}>
                            {route?.next_stop ? (
                                //Display a progress bar that shows the progress of the route using the finished_stops and skipped_stops arrays

                                <Grid item xs={12}>
                                    <Box width={'100%'}>
                                        <Typography
                                            variant={'h5'}
                                            textAlign={'center'}
                                            fontWeight={'bold'}
                                        >
                                            Route Progress
                                        </Typography>

                                        <LinearProgressWithLabel
                                            sx={{
                                                color: theme.palette.primary
                                                    .textWhite,
                                            }}
                                            value={progress}
                                        />
                                    </Box>
                                </Grid>
                            ) : null}
                            <Grid
                                item
                                xs={12}
                                md={6}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Typography
                                    variant={'h5'}
                                    textAlign={'center'}
                                    fontWeight={'bold'}
                                >
                                    Stop Details
                                </Typography>
                                <Box>
                                    <Typography
                                        variant={'h6'}
                                        textAlign={'center'}
                                    >
                                        {
                                            route?.route_customers.find(
                                                (customer) =>
                                                    customer.user_id ===
                                                    route?.next_stop
                                            )?.first_name
                                        }
                                    </Typography>
                                    <Typography
                                        variant={'h6'}
                                        textAlign={'center'}
                                    >
                                        {
                                            route?.route_customers.find(
                                                (customer) =>
                                                    customer.user_id ===
                                                    route?.next_stop
                                            )?.last_name
                                        }
                                    </Typography>
                                    <Typography
                                        variant={'h6'}
                                        textAlign={'center'}
                                    >
                                        {
                                            route?.route_customers.find(
                                                (customer) =>
                                                    customer.user_id ===
                                                    route?.next_stop
                                            )?.address
                                        }
                                    </Typography>
                                </Box>
                                {route?.route_customers.find(
                                    (customer) =>
                                        customer.user_id === route?.next_stop
                                )?.property_notes ? (
                                    <Box padding={'1vh'}>
                                        <Typography
                                            variant={'h6'}
                                            textAlign={'center'}
                                        >
                                            Property Notes:
                                        </Typography>
                                        <Typography
                                            variant={'h6'}
                                            textAlign={'center'}
                                        >
                                            {
                                                route?.route_customers.find(
                                                    (customer) =>
                                                        customer.user_id ===
                                                        route?.next_stop
                                                )?.property_notes
                                            }
                                        </Typography>
                                    </Box>
                                ) : null}

                                {modalStatus === 'success' ? (
                                    <Box padding={'1vh'}>
                                        <Success
                                            title={
                                                'Stop Completed Successfully'
                                            }
                                        />
                                    </Box>
                                ) : modalStatus === 'skipped' ? (
                                    <Box padding={'1vh'}>
                                        <Failed error={'Stop Skipped'} />
                                    </Box>
                                ) : null}
                                {route?.next_stop ? (
                                    <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <Box padding={'1vh'}>
                                            <Button
                                                variant={'contained'}
                                                onClick={() => {
                                                    window.open(
                                                        `http://maps.apple.com/?daddr=${
                                                            route?.route_customers.find(
                                                                (customer) =>
                                                                    customer.user_id ===
                                                                    route?.next_stop
                                                            )?.address
                                                        }`
                                                    )
                                                }}
                                            >
                                                Get Directions
                                            </Button>
                                        </Box>
                                        <Box padding={'1vh'}>
                                            <Button
                                                variant="contained"
                                                onClick={completeStop}
                                                sx={{
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .successGreen,
                                                }}
                                            >
                                                Complete Stop
                                            </Button>
                                        </Box>
                                        <Box padding={'1vh'}>
                                            <Button
                                                variant="contained"
                                                onClick={skipStop}
                                                sx={{
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .errorRed,
                                                }}
                                            >
                                                Skip Stop
                                            </Button>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box padding={'2vh'}>
                                        <Typography
                                            variant={'h6'}
                                            textAlign={'center'}
                                        >
                                            No Stops Remaining
                                        </Typography>
                                    </Box>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box width={'100%'}>
                                    <Box padding={'2vh'}>
                                        <Typography
                                            variant={'h5'}
                                            textAlign={'center'}
                                            fontWeight={'bold'}
                                        >
                                            {route?.next_stop
                                                ? 'Map'
                                                : 'Completed Stops'}
                                        </Typography>
                                    </Box>
                                    <Box padding={'2vh'}>
                                        {route?.next_stop ? (
                                            <img
                                                alt="streetview"
                                                width={'100%'}
                                                src={`https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${
                                                    route?.route_customers.find(
                                                        (customer) =>
                                                            customer.user_id ===
                                                            route?.next_stop
                                                    )?.address
                                                }&key=${
                                                    process.env
                                                        .REACT_APP_GOOGLE_API_KEY
                                                }`}
                                            />
                                        ) : (
                                            <TableContainer>
                                                <Box padding={'2vh'}>
                                                    {route?.finished_stops ? (
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        Name
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        Address
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {
                                                                    //If the stop is in the finished_stops array, display it in the table
                                                                    route?.finished_stops?.map(
                                                                        (
                                                                            stop
                                                                        ) => {
                                                                            return (
                                                                                <TableRow
                                                                                    key={
                                                                                        stop
                                                                                    }
                                                                                >
                                                                                    <TableCell>
                                                                                        {
                                                                                            route?.route_customers.find(
                                                                                                (
                                                                                                    customer
                                                                                                ) =>
                                                                                                    customer.user_id ===
                                                                                                    stop
                                                                                            )
                                                                                                ?.first_name
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {
                                                                                            route?.route_customers.find(
                                                                                                (
                                                                                                    customer
                                                                                                ) =>
                                                                                                    customer.user_id ===
                                                                                                    stop
                                                                                            )
                                                                                                ?.address
                                                                                        }
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )
                                                                        }
                                                                    )
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    ) : (
                                                        <Typography
                                                            variant={'h6'}
                                                            textAlign={'center'}
                                                        >
                                                            No Completed Stops
                                                        </Typography>
                                                    )}
                                                </Box>

                                                <Box padding={'2vh'}>
                                                    <Typography
                                                        variant={'h5'}
                                                        textAlign={'center'}
                                                        fontWeight={'bold'}
                                                    >
                                                        {route?.next_stop
                                                            ? null
                                                            : 'Skipped Stops'}
                                                    </Typography>
                                                    {route?.skipped_stops ? (
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        Name
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        Address
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {
                                                                    //If the stop is in the skipped_stops array, display it in the table
                                                                    route?.skipped_stops?.map(
                                                                        (
                                                                            stop
                                                                        ) => {
                                                                            return (
                                                                                <TableRow
                                                                                    key={
                                                                                        stop
                                                                                    }
                                                                                >
                                                                                    <TableCell>
                                                                                        {
                                                                                            route?.route_customers.find(
                                                                                                (
                                                                                                    customer
                                                                                                ) =>
                                                                                                    customer.user_id ===
                                                                                                    stop
                                                                                            )
                                                                                                ?.first_name
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {
                                                                                            route?.route_customers.find(
                                                                                                (
                                                                                                    customer
                                                                                                ) =>
                                                                                                    customer.user_id ===
                                                                                                    stop
                                                                                            )
                                                                                                ?.address
                                                                                        }
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )
                                                                        }
                                                                    )
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    ) : (
                                                        <Typography
                                                            variant={'h6'}
                                                            textAlign={'center'}
                                                        >
                                                            No Skipped Stops
                                                        </Typography>
                                                    )}
                                                </Box>
                                            </TableContainer>
                                        )}
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                {route?.next_stop ? null : (
                                    <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        {modalStatus === 'completed' ? (
                                            <Success
                                                title={
                                                    'Route Completed Successfully'
                                                }
                                            />
                                        ) : null}
                                        <Box padding={'1vh'}>
                                            <Button
                                                variant="contained"
                                                onClick={completeRoute}
                                                sx={{
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .successGreen,
                                                }}
                                            >
                                                Complete Route
                                            </Button>
                                        </Box>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
