//This will take in a job_id and allow the details to be edited

import React, { useState } from 'react'
import { Box } from '@mui/system'
import { Button } from '@mui/material'
import { FormControl, Input, InputLabel, Typography } from '@mui/material'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Select, MenuItem } from '@mui/material'
import dayjs from 'dayjs'
import axios from 'axios'
import Success from '../../FormStatusIcons/Success'
import { useAuth0 } from '@auth0/auth0-react'

export default function EditCustomerJob(props) {
    const { job, setPopoverVisible } = props
    const { getAccessTokenSilently } = useAuth0()

    const [modifiedJob, setModifiedJob] = useState(job)
    const [formStatus, setFormStatus] = useState('idle')

    const handleSubmit = async () => {
        const accessToken = await getAccessTokenSilently()
        setFormStatus('loading')
        axios
            .post(`${process.env.REACT_APP_BACKEND_DB}/editJob`, modifiedJob, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((response) => {
                setFormStatus('success')
                setTimeout(() => {
                    setFormStatus('idle')
                    if (setPopoverVisible) {
                        setPopoverVisible(false)
                    }
                }, 1000)
            })
    }

    return (
        <Box
            width={'100%'}
            height={'100%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Box textAlign={'center'}>
                <Box mb={'2.5vh'}>
                    <Typography variant="h3">Edit Job</Typography>
                </Box>
            </Box>
            <Box>
                <Box>
                    <InputLabel>Job Description</InputLabel>
                    <Input
                        fullWidth
                        type="text"
                        placeholder={
                            modifiedJob?.job_description ||
                            'Type, for example: Snow Removal'
                        }
                        onChange={(e) =>
                            setModifiedJob({
                                ...modifiedJob,
                                job_description: e.target.value,
                            })
                        }
                    />
                </Box>
                <Box>
                    <InputLabel>Job Notes</InputLabel>
                    <Input
                        fullWidth
                        type="text"
                        placeholder={
                            job?.job_notes || 'Type, for example: Snow Removal'
                        }
                        onChange={(e) => {
                            setModifiedJob({
                                ...modifiedJob,
                                job_notes: e.target.value,
                            })
                        }}
                    />
                </Box>
                <Box>
                    <InputLabel>Start Date:</InputLabel>
                    <DatePicker
                        value={dayjs(job?.start_date) || dayjs(new Date())}
                        onChange={(e) =>
                            setModifiedJob({
                                ...modifiedJob,
                                start_date: e,
                            })
                        }
                    />
                </Box>
                <Box>
                    <InputLabel>End Date:</InputLabel>
                    <DatePicker
                        value={dayjs(job?.end_date) || dayjs(new Date())}
                        onChange={(e) =>
                            setModifiedJob({
                                ...modifiedJob,
                                end_date: e,
                            })
                        }
                    />
                </Box>
                <Box>
                    <InputLabel>Job Status:</InputLabel>
                    {/*
                        
                        Make a MUI dropdown menu with the following options:
                        - In Progress
                        - Completed
                        - Cancelled
                        - Pending
                        - Other
                        
                        */}
                    <FormControl fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                            id="demo-simple-select"
                            value={modifiedJob?.job_status}
                            label="Age"
                            onChange={(e) => {
                                setModifiedJob({
                                    ...modifiedJob,
                                    job_status: e.target.value,
                                })
                            }}
                        >
                            <MenuItem value={'Completed'}>Completed</MenuItem>
                            <MenuItem value={'Cancelled'}>Cancelled</MenuItem>
                            <MenuItem value={'In Progress'}>
                                In Progress
                            </MenuItem>
                            <MenuItem value={'Pending'}>Pending</MenuItem>
                            <MenuItem value={'Other'}>Other</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                {formStatus === 'success' && (
                    <Box padding={1} display={'flex'} justifyContent={'center'}>
                        <Success title={'Job Updated!'}></Success>
                    </Box>
                )}

                <Box mt={'2.5vh'}>
                    {formStatus === 'loading' ? (
                        <Button variant="contained" color="primary" disabled>
                            Updating...
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={formStatus === 'loading'}
                            onClick={handleSubmit}
                        >
                            Update
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
