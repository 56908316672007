import React from 'react'
import { Page, Document, StyleSheet, Image } from '@react-pdf/renderer'
import logo from '../../../../images/ARC.png'

import EstimateTitle from './EstimateTitle'
import EstimateItemsTable from './EstimateItemsTable'
import EstimateThankYouMsg from './EstimateThankYouMsg'
import EstimateNotes from './EstimateNotes'

import CreatedFor from './CreatedFor'

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 50,
        paddingRight: 50,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 84,
        height: 84,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
})

const EstimatePDF = ({ estimateData }) => {
    return (
        <Document title={estimateData.title}>
            <Page size="A4" style={styles.page}>
                <Image style={styles.logo} src={logo} />
                <EstimateTitle title={'Estimate'} />
                <CreatedFor estimate={estimateData} />
                <EstimateItemsTable estimate={estimateData} />
                <EstimateNotes estimate={estimateData} />
                <EstimateThankYouMsg estimate={estimateData} />
            </Page>
        </Document>
    )
}

export default EstimatePDF
