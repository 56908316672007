//This will be a popover modal that will be displayed when an admin clicks on the "Onboard user" button on the admin dashboard.
//This modal will take in the user information, and allow the admin to add any messages, notes, charges or other information to the customer account.
//Use the addUserPopover as the template for this component.

//This will be the admin dashboard component.

import React, { useState } from 'react'
import axios from 'axios'
import { Box } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { Button } from 'react-bootstrap'
import { Close } from '@mui/icons-material'
import { InputLabel, Input, Tooltip } from '@mui/material'
import SingleUserBulkAddCharges from '../../Components/AdminViews/SingleUserBulkAddCharges/SingleUserBulkAddCharges'

import { useTheme } from '@mui/material/styles'

export default function AdminFinishOnboardingPopover(props) {
    const {
        adminFinishOnboardingPopoverDisplayed,
        setAdminFinishOnboardingPopoverDisplayed,
        user,
    } = props
    const [setFirstName] = useState(user.first_name || '')
    const [setLastName] = useState(user.last_name || '')
    const [setAddress] = useState(user.address || '')
    const [setEmail] = useState(user.email || '')
    const [setPhoneNumber] = useState(user.phone_number || '')
    const [setNotes] = useState()

    const theme = useTheme()

    const handleSubmit = async () => {
        axios
            .post //This will be the admin dashboard component.
            ()
    }

    return (
        <Box
            position={'fixed'}
            top={'0'}
            left={'0'}
            width={'100%'}
            height={'100%'}
            backgroundColor={'rgba(0, 0, 0, 0.5)'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={'2'}
            sx={{ backdropFilter: 'blur(5px)' }}
        >
            <Box
                width={'100%'}
                height={'100%'}
                position={'absolute'}
                top={'0'}
                left={'0'}
                zIndex={'2'}
                onClick={() =>
                    setAdminFinishOnboardingPopoverDisplayed(
                        !adminFinishOnboardingPopoverDisplayed
                    )
                }
            ></Box>
            <Box
                zIndex={'3'}
                width={'70%'}
                height={'auto'}
                backgroundColor={theme.palette.primary.textWhite}
                borderRadius={'10px'}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                alignItems={'center'}
                padding={'5vh'}
                mt={'5vh'}
                maxHeight={'75%'}
                overflow={'scroll'}
            >
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                    alignItems={'flex-start'}
                    width={'100%'}
                    height={'100%'}
                    marginBottom={'10px'}
                >
                    <Close
                        position={'static'}
                        color={'black'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={() =>
                            setAdminFinishOnboardingPopoverDisplayed(
                                !adminFinishOnboardingPopoverDisplayed
                            )
                        }
                    />
                </Box>
                <Box
                    width={'75%'}
                    direction={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    textAlign={'center'}
                >
                    <Box sx={{ mb: 2.5 }}>
                        <Typography variant="h3">Update User</Typography>
                    </Box>

                    <Box width={'100%'}>
                        <Grid container>
                            <Grid item xs={6}>
                                <InputLabel>First Name:</InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    fullWidth
                                    placeholder={
                                        user.first_name || 'N/A - Enter a Value'
                                    }
                                    onChange={(e) =>
                                        setFirstName(e.target.value)
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}>
                                <InputLabel>Last Name:</InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    fullWidth
                                    placeholder={
                                        user.last_name || 'N/A - Enter a Value'
                                    }
                                    onChange={(e) =>
                                        setLastName(e.target.value)
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}>
                                <InputLabel>Address:</InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    fullWidth
                                    placeholder={
                                        user.address || 'N/A - Enter a Value'
                                    }
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={6}>
                                <InputLabel>Email:</InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <Tooltip title="Email cannot be changed here.">
                                    <Input
                                        disabled
                                        fullWidth
                                        placeholder={
                                            user.email || 'N/A - Enter a Value'
                                        }
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}>
                                <InputLabel>Phone Number:</InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    fullWidth
                                    type="tel"
                                    placeholder={
                                        user.phone_number ||
                                        'N/A - Enter a Value'
                                    }
                                    onChange={(e) =>
                                        setPhoneNumber(e.target.value)
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}>
                                <InputLabel>Notes:</InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    fullWidth
                                    placeholder="Notes"
                                    onChange={(e) => setNotes(e.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item xs={12} sx={{ marginTop: '1rem' }}>
                                <Button onClick={handleSubmit}>Submit</Button>
                            </Grid>
                        </Grid>

                        <SingleUserBulkAddCharges user_id={user.user_id} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
