//Build the navbar to have the logo on the far left, 64 pixels tall, and 64 pixels wide
//The logo should be clickable and take the user to the home page
//The navbar should have a link to the home page on the to the right of the logo
//The navbar should have a link to the user dashboard on the right side of the home page link
//The navbar should have an avatar icon with a link to the login page on the right side of the user dashboard link
//The navbar should have a logout button on the right side of the avatar icon
//If the screen width is less than 600 pixels, the navbar should be a hamburger menu
//If the screen width is greater than 600 pixels, the navbar should be a full navbar

import React, { Fragment } from 'react'
import {
    AppBar,
    Toolbar,
    Typography,
    Link,
    Button,
    Box,
    Divider,
} from '@mui/material'
import './NavBar.css'
import { useAuth0 } from '@auth0/auth0-react'
import { useTheme } from '@mui/material/styles'
import ARCLOGO from '../../../images/ARC.png'
import NavBarDrawer from './NavBarDrawer'
import { useMediaQuery } from '@mui/material'

export default function NavBar() {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0()

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return isMobile ? (
        <NavBarDrawer dashboardLink={'/dashboard'} />
    ) : (
        <Fragment>
            <AppBar
                position="static"
                style={{
                    backgroundColor: theme.palette.primary.background,
                    height: '64px',
                }}
            >
                <Toolbar
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',

                        alignItems: 'center',
                        height: '64px',
                    }}
                >
                    <Box
                        display={'flex'}
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                        height={'100%'}
                    >
                        <Link
                            href="/"
                            display={'flex'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            //remove underline
                            sx={{
                                textDecoration: 'none',
                            }}
                        >
                            <img
                                src={ARCLOGO}
                                alt="All Round Contracting LLC"
                                title="All Round Contracting LLC"
                                loading="eager"
                                height={'56px'}
                                width={'56px'}
                                style={{
                                    marginRight: '10px',
                                }}
                            />
                            <Typography
                                variant="body1"
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontWeight: 'bold',
                                    //remove underline

                                    //on hover, make white
                                    '&:hover': {
                                        color: theme.palette.primary.textWhite,
                                        //cursor is hand
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                Home
                            </Typography>
                        </Link>
                        {/*Add in a vertical MUI Divider that is 75% of the height of the navbar*/}
                        <Box
                            height={'30%'}
                            display={'flex'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            marginLeft={'10px'}
                        >
                            <Divider
                                orientation="vertical"
                                flexItem
                                color={theme.palette.primary.textWhite}
                            />
                        </Box>

                        <Link
                            href="/services"
                            marginLeft={'10px'}
                            sx={{
                                textDecoration: 'none',
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontWeight: 'bold',

                                    //on hover, make white
                                    '&:hover': {
                                        color: theme.palette.primary.textWhite,
                                        //cursor is hand
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                Services
                            </Typography>
                        </Link>

                        <Box
                            height={'30%'}
                            display={'flex'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            marginLeft={'10px'}
                        >
                            <Divider
                                orientation="vertical"
                                flexItem
                                color={theme.palette.primary.textWhite}
                            />
                        </Box>
                        <Link
                            href="/orderService"
                            marginLeft={'10px'}
                            sx={{
                                textDecoration: 'none',
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontWeight: 'bold',

                                    //on hover, make white
                                    '&:hover': {
                                        color: theme.palette.primary.textWhite,
                                        //cursor is hand
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                Order a Service
                            </Typography>
                        </Link>
                        <Box
                            height={'30%'}
                            display={'flex'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            marginLeft={'10px'}
                        >
                            <Divider
                                orientation="vertical"
                                flexItem
                                color={theme.palette.primary.textWhite}
                            />
                        </Box>
                        <Link
                            href="/contactUs"
                            marginLeft={'10px'}
                            sx={{
                                textDecoration: 'none',
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontWeight: 'bold',
                                    //on hover, make white
                                    '&:hover': {
                                        color: theme.palette.primary.textWhite,
                                        //cursor is hand
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                Contact Us
                            </Typography>
                        </Link>
                    </Box>

                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                        height={'100%'}
                    >
                        {isAuthenticated ? (
                            <Fragment>
                                <Link
                                    href={'/dashboard'}
                                    style={{
                                        textDecoration: 'none',
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: theme.palette.primary.main,
                                            fontWeight: 'bold',
                                            //on hover, make white
                                            '&:hover': {
                                                color: theme.palette.primary
                                                    .textWhite,
                                                //cursor is hand
                                                cursor: 'pointer',
                                            },
                                        }}
                                    >
                                        Dashboard
                                    </Typography>
                                </Link>
                                <Box
                                    height={'30%'}
                                    display={'flex'}
                                    justifyContent={'flex-start'}
                                    alignItems={'center'}
                                    marginLeft={'10px'}
                                >
                                    <Divider
                                        orientation="vertical"
                                        flexItem
                                        color={theme.palette.primary.textWhite}
                                    />
                                </Box>
                                <Button
                                    variant="text"
                                    sx={{
                                        color: theme.palette.primary.main,
                                        fontWeight: 'bold',

                                        //on hover, make white
                                        '&:hover': {
                                            color: theme.palette.primary
                                                .textWhite,
                                            //cursor is hand
                                            cursor: 'pointer',
                                        },
                                    }}
                                    onClick={() =>
                                        logout({
                                            logoutParams: {
                                                returnTo:
                                                    window.location.origin,
                                            },
                                        })
                                    }
                                >
                                    Logout
                                </Button>
                            </Fragment>
                        ) : (
                            <Button
                                variant="text"
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontWeight: 'bold',

                                    //on hover, make white
                                    '&:hover': {
                                        color: theme.palette.primary.textWhite,
                                        //cursor is hand
                                        cursor: 'pointer',
                                    },
                                }}
                                onClick={() =>
                                    loginWithRedirect({
                                        //redirectURI
                                        authorizationParams: {
                                            redirect_uri:
                                                window.location.origin +
                                                '/dashboard',
                                        },
                                    })
                                }
                            >
                                Login
                            </Button>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
        </Fragment>
    )
}
