//charges component

//This is the charges component. It will be imported into the customer dashboard.

import React, { useState, useEffect } from 'react'

import axios from 'axios'
import { Typography } from '@mui/material'

import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'

import Box from '@mui/material/Box'
import { useTheme } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import ViewChargePopover from '../../../modals/ViewChargePopover/ViewChargePopover'
import moment from 'moment'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Grid } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import AddChargePopover from '../../../modals/addChargePopover/addChargePopover'
import EditChargePopover from '../../../modals/EditChargePopover/EditChargePopover'

export default function Charges(props) {
    const { getAccessTokenSilently } = useAuth0()
    const { customerID, getContractorDashboard } = props
    const [charges, setCharges] = useState([])
    const [addChargePopoverVisible, setAddChargePopoverVisible] =
        useState(false)

    const [viewCharge, setViewCharge] = useState(null)
    const [fetchStatus, setFetchStatus] = useState('idle')
    const [paidFilter, setPaidFilter] = useState('all')
    const [viewChargePopoverVisible, setViewChargePopoverVisible] =
        useState(false)

    const [editCharge, setEditCharge] = useState(false)
    const [editChargePopoverVisible, setEditChargePopoverVisible] =
        useState(false)

    const theme = useTheme()

    const handleViewCharge = (charge) => {
        setViewCharge(charge)
        setViewChargePopoverVisible(true)
    }

    const filteredCharges = charges.filter((charge) => {
        if (paidFilter === 'all') {
            return true
        } else if (paidFilter === 'invoiced') {
            return charge.charge_status === 'invoiced'
        } else if (paidFilter === 'pending') {
            return charge.charge_status === 'pending'
        } else if (paidFilter === 'cancelled') {
            return charge.charge_status === 'cancelled'
        }

        return false
    })

    const handleFilterChange = (event, newFilter) => {
        setPaidFilter(newFilter)
    }

    const viewChargeFunction = (charge) => {
        setViewCharge(charge)
        setViewChargePopoverVisible(!viewChargePopoverVisible)
    }

    const editChargeFunction = (charge) => {
        setEditCharge(charge)
        setEditChargePopoverVisible(!editChargePopoverVisible)
    }

    const updateChargeToPaid = (chargeID) => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_DB}/updateChargeToInvoiced`,
                    {
                        chargeID: chargeID,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then(() => {
                    axios
                        .get(
                            `${process.env.REACT_APP_BACKEND_DB}/getCharges/${customerID}`,
                            {
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }
                        )
                        .then((response) => {
                            setCharges(response.data)
                        })
                })
        }
        getJWT()
    }

    useEffect(() => {
        const getJWT = async () => {
            setFetchStatus('loading')
            const accessToken = await getAccessTokenSilently()

            axios
                .get(
                    `${process.env.REACT_APP_BACKEND_DB}/getCharges/${customerID}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    setFetchStatus('success')
                    setCharges(response.data)
                })
        }

        //only get the JWT if the popovers are not visible
        if (!addChargePopoverVisible && !editChargePopoverVisible) {
            getJWT()
        }
    }, [
        customerID,
        addChargePopoverVisible,
        editChargePopoverVisible,
        getAccessTokenSilently,
    ])

    if (fetchStatus !== 'success') {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                >
                    <Typography
                        variant="h4"
                        color={theme.palette.primary.textWhite}
                    >
                        Charges
                    </Typography>
                </Box>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box>
            {addChargePopoverVisible && (
                <AddChargePopover
                    setPopoverVisible={setAddChargePopoverVisible}
                    customerID={customerID}
                />
            )}
            {viewChargePopoverVisible && (
                <ViewChargePopover
                    setPopoverVisible={setViewChargePopoverVisible}
                    charge={viewCharge}
                />
            )}
            {editChargePopoverVisible && (
                <EditChargePopover
                    setPopoverVisible={setEditChargePopoverVisible}
                    charge={editCharge}
                    getContractorDashboard={getContractorDashboard}
                />
            )}

            <Box
                display={'flex'}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Typography
                    variant="h4"
                    color={theme.palette.primary.textWhite}
                >
                    Charges
                </Typography>
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => setAddChargePopoverVisible(true)}
                >
                    Add Charge
                </Button>
            </Box>
            <Box
                display={'flex'}
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Typography
                    variant="h6"
                    color={theme.palette.primary.textWhite}
                    style={{ marginRight: '10px' }}
                >
                    Filter by:
                </Typography>

                <ToggleButtonGroup
                    value={paidFilter}
                    exclusive
                    onChange={handleFilterChange}
                    style={{ backgroundColor: 'black' }}
                >
                    <ToggleButton
                        value="all"
                        style={{
                            backgroundColor:
                                paidFilter === 'all' ? 'gray' : 'black',
                            color: paidFilter === 'all' ? 'white' : 'gray',
                        }}
                    >
                        All
                    </ToggleButton>
                    <ToggleButton
                        value="invoiced"
                        style={{
                            backgroundColor:
                                paidFilter === 'invoiced' ? 'gray' : 'black',
                            color: paidFilter === 'invoiced' ? 'white' : 'gray',
                        }}
                    >
                        Invoiced
                    </ToggleButton>
                    <ToggleButton
                        value="pending"
                        style={{
                            backgroundColor:
                                paidFilter === 'pending' ? 'gray' : 'black',
                            color: paidFilter === 'pending' ? 'white' : 'gray',
                        }}
                    >
                        Pending
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            {!filteredCharges.length ? (
                <Typography
                    variant="body1"
                    textAlign={'center'}
                    color={theme.palette.primary.textWhite}
                >
                    No Results Found
                </Typography>
            ) : null}
            {filteredCharges
                ?.sort((a, b) => {
                    return new Date(b.date_serviced) - new Date(a.date_serviced)
                })
                .map((charge, index) => (
                    <Card
                        key={index}
                        style={{
                            margin: '10px',
                            border: '1px solid black',
                            //hover effect
                        }}
                    >
                        <CardContent
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'lightgray',
                                    cursor: 'pointer',
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                position: 'relative',
                            }}
                        >
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    onClick={() => handleViewCharge(charge)}
                                >
                                    <Typography
                                        variant="h6"
                                        style={{
                                            fontWeight: 'bold',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Charge #{charge.charge_id}
                                    </Typography>
                                    <Typography>
                                        Service Performed:{' '}
                                        {charge.service_performed}
                                    </Typography>
                                    <Typography>
                                        Date Serviced:{' '}
                                        {moment
                                            .utc(charge.date_serviced)

                                            .format('MM/DD/YYYY')}
                                    </Typography>
                                </Grid>

                                <Grid
                                    container
                                    item
                                    xs={12}
                                    sm={6}
                                    justifyContent={'right'}
                                    alignItems={'flex-end'}
                                >
                                    <Grid item xs={12}>
                                        <Typography
                                            display={'flex'}
                                            flexDirection={'row'}
                                            justifyContent={'right'}
                                            alignItems={'flex-end'}
                                            variant="h6"
                                            style={{
                                                fontWeight: 'bold',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            Charge Amount:{' '}
                                            {new Intl.NumberFormat('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            }).format(charge.amount)}
                                        </Typography>
                                        <Box
                                            display={'flex'}
                                            flexDirection={'row'}
                                            alignItems={'center'}
                                            justifyContent={'flex-end'}
                                        >
                                            <Typography paddingRight={1}>
                                                Charge Status:{' '}
                                            </Typography>
                                            <Typography
                                                display={'flex'}
                                                flexDirection={'row'}
                                                justifyContent={'right'}
                                                alignItems={'flex-end'}
                                            >
                                                {charge.charge_status ===
                                                'invoiced' ? (
                                                    <span
                                                        style={{
                                                            color: theme.palette
                                                                .primary
                                                                .successGreen,
                                                            fontWeight: 'bold',
                                                            fontSize: '1.2em',
                                                        }}
                                                    >
                                                        [INVOICED]
                                                    </span>
                                                ) : charge.charge_status ===
                                                  'pending' ? (
                                                    <span
                                                        style={{
                                                            color: theme.palette
                                                                .primary
                                                                .cautionYellow,
                                                            fontWeight: 'bold',
                                                            fontSize: '1.2em',
                                                        }}
                                                    >
                                                        [PENDING]
                                                    </span>
                                                ) : charge.charge_status ===
                                                  'cancelled' ? (
                                                    <span
                                                        style={{
                                                            color: theme.palette
                                                                .primary
                                                                .errorRed,
                                                            fontWeight: 'bold',
                                                            fontSize: '1.2em',
                                                        }}
                                                    >
                                                        [CANCELLED]
                                                    </span>
                                                ) : null}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box
                                            display={'flex'}
                                            flexDirection={'row'}
                                            alignItems={'flex-end'}
                                            justifyContent={'right'}
                                            height={'100%'}
                                        >
                                            {
                                                //if the charge has been invoiced, show the view charge button
                                                charge.charge_status ===
                                                'invoiced' ? null : (
                                                    <Box padding>
                                                        <Button
                                                            onClick={() =>
                                                                editChargeFunction(
                                                                    charge
                                                                )
                                                            }
                                                            variant="contained"
                                                            size="small"
                                                        >
                                                            Edit Charge
                                                        </Button>
                                                    </Box>
                                                )
                                            }
                                            <Box padding>
                                                <Button
                                                    onClick={() =>
                                                        viewChargeFunction(
                                                            charge
                                                        )
                                                    }
                                                    variant="contained"
                                                    size="small"
                                                >
                                                    View Charge
                                                </Button>
                                            </Box>
                                            {charge.charge_status ===
                                            'cancelled' ? null : (
                                                <Box padding>
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        disabled={
                                                            charge.charge_status ===
                                                            'invoiced'
                                                        }
                                                        onClick={() =>
                                                            updateChargeToPaid(
                                                                charge.charge_id
                                                            )
                                                        }
                                                    >
                                                        {charge.charge_status ===
                                                        'invoiced'
                                                            ? 'Invoiced'
                                                            : 'Mark as Invoiced'}
                                                    </Button>
                                                </Box>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                ))}
        </Box>
    )
}
