import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { Auth0Provider } from '@auth0/auth0-react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import ReactGA from 'react-ga4'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
ReactGA.initialize(trackingId)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        }}
    >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <App />
        </LocalizationProvider>
    </Auth0Provider>
)
